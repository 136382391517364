import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useProjectStore = defineStore('project', () => {
  const axios = inject(AXIOS) || baseAxios;

  const searchName = ref(null);
  const sortType = ref('1');
  const project = ref({});
  const paginatedProjectList = ref([]);
  const projectList = ref([]);
  const currentPage = ref(1);

  const getProjectListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createProjectRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getProjectRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getPaginatedProjectList(page = 1) {
    getProjectListRequest.value = {};
    const req = getProjectListRequest.value;
    req.isLoading = true;
    currentPage.value = page;

    return axios
      .get(api.projects(), {
        params: { page, search: searchName.value }
      })
      .then((resp) => {
        paginatedProjectList.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getProjectList() {
    getProjectListRequest.value = {};
    const req = getProjectListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.projects(), {
        params: { paginated: 0, search: searchName.value }
      })
      .then((resp) => {
        projectList.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createProject(name, locales, defaultLocale) {
    createProjectRequest.value = {};
    const req = createProjectRequest.value;
    req.isLoading = true;

    return axios
      .post(api.projects(), {
        name,
        locales,
        default_locale: defaultLocale
      })
      .then(() => {
        getPaginatedProjectList(currentPage.value);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getProject(projectId) {
    getProjectRequest.value = {};
    const req = getProjectRequest.value;
    req.isLoading = true;

    return axios
      .get(api.projectById({ projectId }))
      .then((resp) => {
        project.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateProject(projectId, description) {
    getProjectRequest.value = {};
    const req = getProjectRequest.value;
    req.isLoading = true;

    return axios
      .get(api.projectById({ projectId, description }))
      .then(() => {
        getProject(projectId);
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    searchName,
    sortType,
    paginatedProjectList,
    project,
    projectList,
    createProjectRequest,
    getProjectListRequest,
    getProjectRequest,
    getPaginatedProjectList,
    createProject,
    getProject,
    updateProject,
    getProjectList,
  };
});
