<script setup lang="js">
import { ref } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { deleteRegTable } = useRegistryStore();
const registryStore = useRegistryStore();
const { regTables } = storeToRefs(registryStore);
const id = ref(null);

function deleteTable() {
  deleteRegTable(projectId.value, id.value).then(() => {
    emit('save');
    closeModal();
  });
}
</script>

<template>
  <ModalTemplateSide @submit="deleteTable">
    <template v-slot:title>Удалить таблицу</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Выберите таблицу</label>
        <select v-model="id" class="form-select">
          <option :value="regTable.id" v-for="regTable in regTables">{{ regTable.id }}</option>
        </select>
      </div>
    </template>
    <template v-slot:submit>Удалить</template>
  </ModalTemplateSide>
</template>
