import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useViewFieldStore = defineStore('viewField', () => {
  const axios = inject(AXIOS) || baseAxios;

  const viewFieldList = ref([]);

  const typeList = ref({
    number: 'Число',
    datetime: 'Дата',
    text: 'Текст',
    boolean: 'boolean',
    identificator: 'identificator'
  });

  const getViewFieldListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createViewFieldRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const changeFormViewOrderRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getViewFieldList(projectId, bpId, nodeId) {
    getViewFieldListRequest.value = {};
    const req = getViewFieldListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.formView({ projectId, bpId, nodeId }))
      .then((resp) => {
        getViewFieldListRequest.value = {};
        viewFieldList.value = resp.data.sort((a, b) => a.order - b.order);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createViewField(
    projectId,
    bpId,
    nodeId,
    creating_input_field_ids,
    creating_input_catalog_ids,
    creating_input_static_ids,
    creating_input_table_ids,
    removing_view_field_ids,
    removing_view_catalog_ids,
    removing_view_static_ids,
    removing_view_table_ids
  ) {
    createViewFieldRequest.value = {};
    const req = createViewFieldRequest.value;
    req.isLoading = true;

    return axios
      .post(api.formViewUpdate({ projectId, bpId, nodeId }), {
        creating_input_field_ids,
        creating_input_catalog_ids,
        creating_input_static_ids,
        creating_input_table_ids,
        removing_view_field_ids,
        removing_view_catalog_ids,
        removing_view_static_ids,
        removing_view_table_ids
      })
      .then(() => {
        createViewFieldRequest.value = {};
        getViewFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function changeFormViewOrder(projectId, bpId, nodeId, fields, tables, catalogs, statics) {
    changeFormViewOrderRequest.value = {};
    const req = changeFormViewOrderRequest.value;
    req.isLoading = true;
    return axios
      .post(api.formViewOrder({ projectId, bpId, nodeId }), {
        fields,
        tables,
        catalogs,
        statics
      })
      .then(() => {
        changeFormViewOrderRequest.value = {};
        getViewFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function clearRequestVariables() {
    getViewFieldListRequest.value = {};
    createViewFieldRequest.value = {};
    changeFormViewOrderRequest.value = {};
  }

  return {
    getViewFieldList,
    createViewField,
    changeFormViewOrder,
    clearRequestVariables,
    viewFieldList,
    typeList,
    getViewFieldListRequest,
    createViewFieldRequest,
    changeFormViewOrderRequest
  };
});
