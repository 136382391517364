import { useAuthStore } from '@/stores/auth.store';

import type { Axios } from 'axios';

export function setInterceptor(axios: Axios) {
  axios.interceptors.request.use((request) => {
    const authStore = useAuthStore();

    if (authStore.authorized) {
      request.headers.setAuthorization(`Bearer ${authStore.token}`);
    }

    return request;
  });
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          const authStore = useAuthStore();
          authStore.token = null;
        }
        if (!(error.response.status >= 200 && error.response.status < 300)) {
          return Promise.reject(error.response.data);
        }
      } else {
        return Promise.reject(`Network or other error: ${error}`);
      }
    }
  );
}
