import './assets/css/main.scss';
import * as bootstrap from 'bootstrap';

import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { AXIOS } from './const';
import { setInterceptor } from './router/axios';

const app = createApp(App);

app.use(createPinia());

setInterceptor(axios);
app.use(VueAxios, axios);
app.provide(AXIOS, axios);

app.use(router);

app.mount('#app');
