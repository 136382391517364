<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRouter } from 'vue-router';
import { useNodeStore } from '@/stores/node.store';

const router = useRouter();
const { closeModal } = useModal();
const inputGeometriesStore = useInputGeometriesStore();
const { inputGeometryList, createInputGeometryRequest } = storeToRefs(inputGeometriesStore);
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const title = ref({});
const type = ref('');
const settings = ref(null);
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');
const types = [
  { id: null, text: '' },
  { id: 'Polygon', value: 'Полигон' },
  { id: 'LineString', value: 'Линия' },
  { id: 'Point', value: 'Точка' }
];
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);
const childNodes = bpNode.value.child_nodes;
const child_node_ids = ref([]);

onMounted(() => {
  inputGeometriesStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
});

function getTitle(title) {
  return title[project.value.default_locale];
}
function create() {
  inputGeometriesStore
    .createInputGeometry(
      projectId.value,
      bpId.value,
      nodeId.value,
      title.value,
      settings.value,
      type.value,
      inputGeometryList.value.length + 1,
      child_node_ids.value
    )
    .then(() => {
      closeModal();
    });
}
function isErrorField(fieldName) {
  if (createInputGeometryRequest.value.error?.errors) {
    return createInputGeometryRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createInputGeometryRequest.value.error?.errors)) {
    return createInputGeometryRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="create">
    <template v-slot:title>Добавить геометрию для заполнения</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование геометрии на {{ locale.name }}</label>
        <input type="text" v-model="title[locale.id]" class="form-control"
          :class="{ 'error': isErrorField('title') }" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select v-model="type" class="form-select" :class="{ 'error': isErrorField('type') }">
          <option v-for="t in types" :key="t.id" :value="t.id">
            {{ t.value }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Инструмент</label>
        <select v-model="settings" class="form-select" :class="{ 'error': isErrorField('settings') }">
          <option></option>
        </select>
        <p v-if="isErrorField('settings')" class="error-mesage">
          <span v-for="error in isErrorField('settings')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <select v-model="child_node_ids" class="form-select" multiple :class="{ 'error': isErrorField('child_node_ids') }">
          <option v-for="node in childNodes" :key="node.id" :value="node.current.id">
            {{ getTitle(node.current.node.title) }}
          </option>
        </select>
        <p v-if="isErrorField('child_node_ids')" class="error-mesage">
          <span v-for="error in isErrorField('child_node_ids')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
