<script setup lang="js">
import { ref } from 'vue';
const selectAllChecked = ref(false);
const nodeOpenStates = ref({});
function selectAll() {}
function toggleAll() {}
function toggleNode() {}
</script>
<template>
  <div class="geometry-layers">
    <div class="d-flex justify-content-between">
      <div class="form-label d-flex align-items-center">
        <input
          type="checkbox"
          class="me-3 modal-checkbox"
          v-model="selectAllChecked"
          @change="selectAll"
        />Выделить все
      </div>
      <div class="form-label" @click="toggleAll">
        Свернуть все
        <img src="@/assets/img/minus-ico-with-border.svg" class="ms-3" />
      </div>
    </div>
    <div class="d-flex justify-content-between flex-column">
      <div class="modal-nodes d-flex justify-content-between">
        <span> Категория</span>
        <img
          src="@/assets/img/plus-ico-with-border.svg"
          @click="toggleNode(ancestor.current.id)"
          v-if="!nodeOpenStates"
        />
        <img
          src="@/assets/img/minus-ico-with-border.svg"
          @click="toggleNode(ancestor.current.id)"
          v-if="nodeOpenStates"
        />
      </div>
      <div v-if="nodeOpenStates" class="node-docs">
        <div class="node-doc-list d-flex justify-content-between align-items-start mt-3">
          <div class="d-flex align-items-center">
            <input type="checkbox" v-model="checkedDoc" class="me-1 modal-checkbox" />
            <span>Название слоя</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="orange-btn">Сохранить</div>
    </div>
  </div>
</template>
