<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useNodeStore } from '@/stores/node.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { typeList, inputField, updateInputFieldRequest } = storeToRefs(inputFieldStore);
const childNodes = bpNode.value.child_nodes;

const title = ref({});
const type = ref("");
const order = ref(0);
const required = ref(false);
const settings = ref(null);
const child_node_ids = ref([]);

onMounted(() => {
  inputFieldStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  if (Object.keys(inputField.value).length) {
    title.value = inputField.value.field.title;
    type.value = inputField.value.field.type;
    order.value = inputField.value.field.order;
    required.value = inputField.value.field.required;
    //settings.value = inputField.value.settings;
    inputField.value.directions.forEach(direction => {
      child_node_ids.value.push(direction.next_bp_node_id);
    });
  }
});

function updateInputField() {
  inputFieldStore.updateInputField(projectId.value, bpId.value, nodeId.value, title.value, type.value, order.value, required.value, settings.value, child_node_ids.value)
  .then(() => {
    closeModal();
  });
}

function getTitle(title) {
  return title[project.value.default_locale]
}
function isErrorField(fieldName) {
  if (updateInputFieldRequest.value.error?.errors) {
    return updateInputFieldRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(updateInputFieldRequest.value.error?.errors)) {
    return updateInputFieldRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="updateInputField">
    <template v-slot:title>Редактировать поле</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование поля({{ locale.name }})</label>
        <input type="text" v-model="title[locale.id]" class="form-control" :class="{'error': isErrorField('title')}" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select v-model="type" class="form-select" :class="{'error': isErrorField('type')}">
          <option v-for="typeKey in Object.keys(typeList)" :key="typeKey" :value="typeKey">
            {{ typeList[typeKey] }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <select v-model="child_node_ids" class="form-select" multiple :class="{'error': isErrorField('child_node_ids')}">
          <option v-for="node in childNodes" :key="node.id" :value="node.current.id">
            {{ getTitle(node.current.node.title) }}
          </option>
        </select>
        <p v-if="isErrorField('child_node_ids')" class="error-mesage">
          <span v-for="error in isErrorField('child_node_ids')">{{ error }}</span>
        </p>
      </div>
      <!-- <div class="mt-3">
        <label class="form-label">Порядок</label>
        <input type="number" v-model="order" class="form-control" min="0"/>
      </div> -->
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Обязательность</label>
        <basic-toggle-switch v-model="required" />
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
