<template>
  <label class="switch">
    <input type="checkbox" :checked="modelValue" @change="toggle" :disabled="disabled" />
    <span class="slider round" :class="{ disabled: disabled }"></span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle(event) {
      this.$emit('update:modelValue', event.target.checked);
    }
  }
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #6263d5;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.slider.disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}
</style>
