<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectTeamStore } from '@/stores/projectTeam.store';
import { useUserStore } from '@/stores/user.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import Search from '@/components/Search.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const projectTeamStore = useProjectTeamStore();
const userStore = useUserStore();
const { closeModal } = useModal();
const { searchName, userList } = storeToRefs(userStore);
const { teamUserList, updateUserListRequest } = storeToRefs(projectTeamStore);
const { updateTeamUserList } = useProjectTeamStore();
const { getUserList } = useUserStore();

const checking_users = ref([]);
const isCheckedAll = ref(false);

onMounted(() => {
  getUserList();
  teamUserList.value.forEach((teamUser) => {
    checking_users.value.push(teamUser.id);
  })
});

function saveChanges() {
  updateTeamUserList(checking_users.value).then(() => {
    closeModal();
  });
}

function isChecked(id) {
  return checking_users.value.includes(id);
}

function selectUser(id) {
  if (isChecked(id)) {
    checking_users.value = checking_users.value.filter((userId) => userId != id);
  } else {
    checking_users.value.push(id);
  }
}
function selectAll() {
  userList.value.forEach(user => {
    if (!isChecked(user.id)){
      checking_users.value.push(user.id);
    }
  });
  isCheckedAll.value = true;
}

function unselectAll() {
  isCheckedAll.value = false;
  checking_users.value = [];
}
function isHasError() {
  return updateUserListRequest.value.error;
}
</script>

<template>
  <ModalTemplateSide @submit="saveChanges">
    <template v-slot:title>Управление пользователями</template>
    <template v-slot:body>
      <div class="mt-4 team-modal-search">
        <Search @search="getUserList()" :value="searchName" @input="searchName = $event.target.value" :placeholderText="'Поиск пользователя'" />
      </div>
      <div class="table-responsive mt-3">
        <table class="table gray-table">
          <tbody>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <span @click="selectAll()" class="table-checkbox" :class="{ active: isCheckedAll }" />
                  <span class="gray-regular-text ml-2" @click="selectAll()">Выделить все</span>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center justify-content-end">
                  <span class="gray-regular-text" @click="unselectAll()">Сбросить все</span>
                </div>
              </td>
            </tr>
            <tr v-for="user in userList">
              <td>
                <div class="d-flex align-items-center">
                  <span @click="selectUser(user.id)" class="table-checkbox" :class="{ active: isChecked(user.id) }" />
                  <span class="ml-2">{{ user.name }}</span>
                </div>
              </td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-if="isHasError()" class="error-mesage">
        {{ isHasError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
