<script setup lang="js">
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import { useSubmoduleStore } from '@/stores/submodule.store';

const submoduleStore = useSubmoduleStore();
const { group } = storeToRefs(submoduleStore);
const submoduleGroupId = useRouteParams('submoduleGroupId');

onMounted(() => {
  submoduleStore.getSubmoduleGroup(submoduleGroupId.value);
});
</script>

<template>
  <div>
    <div class="container-fluid">
      <div class="d-flex mt-3 page-header">
        <RouterLink :to="{ name: 'submodule-group-list' }" class="back-btn" />
        <h1 v-if="group">{{ group.name }}</h1>
      </div>
    </div>
    <div class="project-tabs">
      <RouterLink :to="{ name: 'end-submodule-list',
        params: {
          submoduleGroupId
        }
      }" class="project-tab"> Код </RouterLink>
      <RouterLink :to="{ name: 'common-submodule-list',
        params: {
          submoduleGroupId
        }
      }" class="project-tab"> Сервер </RouterLink>
    </div>
    <router-view />
  </div>
</template>
