<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
import { useNodeStore } from '@/stores/node.store';
import { useProjectStore } from '@/stores/project.store';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import InputDocumentChildNodeModal from '@/components/modals/InputDocumentChildNodeModal.vue';

const inputDocumentsStore = useInputDocumentsStore();
const projectStore = useProjectStore();
const nodeStore = useNodeStore();
const { openModal, activeModal } = useModal();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { inputDocumentsList } = storeToRefs(inputDocumentsStore);
const { bpNode } = storeToRefs(nodeStore);
const { project } = storeToRefs(projectStore);
const selectedChildBpNode = ref({});
const selectedChildBpNodeId = ref('');
const selectedChildPivotId = ref("");

onMounted(() => {
  inputDocumentsStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputDocumentsStore.getInputDocumentsList(projectId.value, bpId.value, nodeId.value);
  nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
});
function selectNode(bpNode) {
  selectedChildBpNode.value = bpNode;
  selectedChildBpNodeId.value = bpNode.id;
  selectedChildPivotId.value = bpNode.pivot.id;
}
function nodeName(title) {
  return title[project.value.default_locale];
}
function getTitle(doc) {
  return doc?.document?.title[project.value.default_locale];
}
function showDoc(doc) {
  return doc?.directions.some((direction) => direction.next_bp_node_id == selectedChildBpNodeId.value);
}
</script>

<template>
  <div class="a">
    <div class="d-flex align-items-center justify-content-between input-fields__btns mt-2">
      <div class="text-uppercase col-md-4 px-3">
        <b>Настроить документы для дочерних узлов</b>
      </div>
      <div class="d-flex align-items-center justify-content-end flex-wrap mr-3 col-md-8 px-3">
        <RouterLink
          :to="{
            name: 'input-documents',
            params: {
              projectId: projectId,
              bpId: bpId,
              nodeId: nodeId
            }
          }"
          class="gray-btn"
        >
          Закрыть
        </RouterLink>
      </div>
    </div>
    <div class="bp-section-content_child-links">
      <div
        class="bp-section-content_child-link"
        v-for="childBpNode in bpNode.child_nodes"
        :key="childBpNode.id"
        @click="selectNode(childBpNode)"
        :class="{ active: selectedChildBpNodeId == childBpNode.id }"
      >
        {{ nodeName(childBpNode.current.node.title) }}
      </div>
    </div>
    <div class="bp-section-content_node-name px-3 my-3" v-if="selectedChildBpNodeId">
      <b>{{ nodeName(selectedChildBpNode.current.node.title) }}</b>
    </div>
    <button class="simple-btn mt-3 mb-3 ml-3" @click="openModal('addDocForChildsModal')" v-if="selectedChildBpNodeId">
      <span class="orange-plus" />
      Добавить
    </button>
  </div>
  <div class="table-responsive pb-5">
    <table class="table gray-table" v-if="selectedChildBpNodeId">
      <thead>
        <tr>
          <th>Наименование документа</th>
          <th>Генерируемый</th>
          <th>Подпись</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="inputDocument in inputDocumentsList" :key="inputDocument.id">
          <tr v-if="showDoc(inputDocument)">
            <td>
              <div class="d-flex align-items-center">
                <span class="name">{{ getTitle(inputDocument) }}</span>
              </div>
            </td>
            <td v-if="inputDocument?.templates?.length === 0">Загружаемый</td>
            <td v-else>Генерируемый</td>
            <td>
              {{ inputDocument.signable ? 'Да' : 'Нет' }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <InputDocumentChildNodeModal
    v-if="activeModal === 'addDocForChildsModal'"
    :selectedChildPivotId="selectedChildPivotId"
    :selectedChildBpNodeId="selectedChildBpNodeId"
  />
</template>
