<script setup lang="js">
import { useRoute } from 'vue-router';
import { useNodeStore } from '@/stores/node.store';
const route = useRoute();
const nodeStore = useNodeStore();




</script>
<template>
  <div class="bp-section-block" :class="{ show: nodeStore.isShowSidebar }">
    <img src="@/assets/img/bp-sections-btn.svg" @click="nodeStore.isShowSidebar = !nodeStore.isShowSidebar" />
    <div class="bp-section-content" v-if="nodeStore.isShowSidebar">
      <router-view />
    </div>
    <div class="bp-section-links">
      <RouterLink
        :to="{
          name: 'input-fields',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: route.params.nodeId
          }
        }"
        class="bp-section_field"
      />
      <RouterLink
        :to="{
          name: 'input-documents',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: route.params.nodeId
          }
        }"
        class="bp-section_document"
      />
      <RouterLink
        :to="{
          name: 'input-geometry',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: route.params.nodeId
          }
        }"
        class="bp-section_geometry"
      />
      <RouterLink
        :to="{
          name: 'autoforms',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: route.params.nodeId
          }
        }"
        class="bp-section_formula"
      />
    </div>
  </div>
</template>
