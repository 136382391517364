<script setup lang="js">
import { useRoute } from 'vue-router';
const route = useRoute();
</script>

<template>
  <div class="bp-section-content_child-links">
    <RouterLink
      :to="{
        name: 'input-geometry',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_child-link"
      :class="{ active: route.name == 'input-geometry' }"
    >
      Заполняемая
    </RouterLink>
    <RouterLink
      :to="{
        name: 'view-geometry',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_child-link"
      :class="{ active: route.name == 'view-geometry' }"
    >
      Просматриваемая
    </RouterLink>
  </div>
  <router-view />
</template>
