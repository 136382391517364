<script setup lang="js">
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import CommonSubmoduleCreateModal from '@/components/modals/CommonSubmoduleCreateModal.vue';
import CommonSubmoduleUpdateModal from '@/components/modals/CommonSubmoduleUpdateModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import useModal from '@/stores/modal.store';
import { useSubmoduleStore } from '@/stores/submodule.store';

const submoduleStore = useSubmoduleStore();
const submoduleGroupId = useRouteParams('submoduleGroupId');
const { activeModal, openModal, closeModal } = useModal();
const { commonSubmoduleList, commonSubmodule } = storeToRefs(submoduleStore);

onMounted(() => {
  submoduleStore.getCommonSubmodules(submoduleGroupId.value);
});

function deleteSubmodule() {
  submoduleStore.deleteCommonSubmodule(submoduleGroupId.value, commonSubmodule.value.id).then(() => {
    closeModal();
  });
}
function openModals(modalName, item) {
  activeModal.value = modalName;
  commonSubmodule.value = item;
}
</script>

<template>
  <div class="container-fluid admin-view">
    <div class="row">
      <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
        <div class="d-flex align-items-center flex-wrap">
        </div>
        <div @click="openModal('commonSubmoduleCreateModal')" class="orange-btn">
          <img src="@/assets/img/plus-ico.svg" alt="" /> Создать субмодуль
        </div>
      </div>
      <div class="table-responsive mt-4">
        <table class="table gray-table">
          <thead>
            <tr>
              <th>Название</th>
              <th>Описание</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in commonSubmoduleList" :key="item.id">
              <td>
                {{ item.name }}
              </td>
              <td>{{ item.description }}</td>
              <td class="table-buttons">
                <div class="table-button" @click="openModals('commonSubmoduleUpdateModal', item)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="openModals('commonSubmoduleDeleteModal', item)">
                  <span class="delite-ico" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <DeleteModal v-if="activeModal === 'commonSubmoduleDeleteModal'" :itemText="'субмодуль'"
      @confirmDelete="deleteSubmodule()" />
    <CommonSubmoduleCreateModal v-if="activeModal === 'commonSubmoduleCreateModal'" />
    <CommonSubmoduleUpdateModal v-if="activeModal === 'commonSubmoduleUpdateModal'" />
  </div>
</template>