<script setup lang="js">
    import { ref } from 'vue';
    import { defineProps } from 'vue';

    const emit = defineEmits(['delete', 'update', 'columns']);
    const props = defineProps({
        menuItems: Array
    });
    const isShow = ref(false);

    function chooseOtion(option){
        isShow.value = false;
        emit(option);
    }
</script>
<template>   
    <div class="context-menu">
        <span class="context-menu_toggle-btn" @click="isShow = !isShow" />
        <div class="context-menu_items"  v-show="isShow">
            <div class="context-menu_item" @click="chooseOtion('columns')" v-if="menuItems && menuItems.includes('columns')">
                <span class="context-menu_columns-ico" />
                Столбцы
            </div>
            <div class="context-menu_item" @click="chooseOtion('update')" v-if="menuItems && menuItems.includes('update')">
                <span class="context-menu_edit-ico" />
                Редактировать
            </div>
            <div class="context-menu_item" @click="chooseOtion('delete')" v-if="menuItems && menuItems.includes('delete')">
                <span class="context-menu_delete-ico" />
                Удалить
            </div>
        </div>
    </div>
</template>