<script setup lang="js">
import { ref } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { deleteTableLink } = useRegistryStore();
const registryStore = useRegistryStore();
const { regTableLinks, deleteTableLinkRequest } = storeToRefs(registryStore);
const regLink = ref({});

function deleteLink() {
  deleteTableLink(projectId.value, regLink.value.column_from.reg_table_id, regLink.value.id).then(() => {
    emit('save');
    closeModal();
  });
}

function optionText(table1Id, column1Name, table2Id, column2Name) {
  return `${column1Name}(Таблица ${table1Id}) -> ${column2Name}(Таблица ${table2Id})`
}
function isHasError() {
  return deleteTableLinkRequest.value.error;
}
</script>

<template>
  <ModalTemplateSide @submit="deleteLink">
    <template v-slot:title>Удалить связь</template>
    <template v-slot:body>
      <label class="form-label mt-3">Выберите связь</label>
      <select v-model="regLink" class="form-select">
        <option :value="link" v-for="link in regTableLinks">
          {{ optionText(link.column_from.reg_table_id, link.column_from.name, link.column_to.reg_table_id, link.column_to.name) }}
        </option>
      </select>
      <p v-if="isHasError()" class="error-mesage">
        {{ isHasError() }}
      </p>
    </template>
    <template v-slot:submit>Удалить</template>
  </ModalTemplateSide>
</template>
