import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useNodeStore = defineStore('node', () => {
  const axios = inject(AXIOS) || baseAxios;

  const nodeList = ref([]);

  const bpNode = ref({});

  const roleList = ref({
    applicant: 'Заявитель',
    employee: 'Сотрудник',
    autonode: 'Автоузел',
    custom: 'custom'
  });

  const directionTypeList = ref({
    all: 'Все',
    one: 'Один из'
  });

  const isShowSidebar = ref(false);
  const getNodeListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteNodeRefRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const dataForDeleteNodeRef = ref({
    sourceNode: null,
    targetNode: null
  });

  function getNodeList(projectId, bpId) {
    getNodeListRequest.value = {};
    const req = getNodeListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.nodes({ projectId, bpId }))
      .then((resp) => {
        nodeList.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createNode(
    projectId,
    bpId,
    title,
    desc,
    role,
    direction_type,
    parent_bp_node_id,
    direction_field_title,
    direction_title,
    set_refuse
  ) {
    createNodeRequest.value = {};
    const req = createNodeRequest.value;
    req.isLoading = true;
    let description = null;
    if (Object.keys(desc).length) {
      description = desc;
    }
    return axios
      .post(api.nodes({ projectId, bpId }), {
        title,
        description,
        role,
        direction_type,
        parent_bp_node_id,
        direction_field_title,
        direction_title,
        set_refuse
      })
      .then(() => {
        bpNode.value = {};
        getNodeList(projectId, bpId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateNode(
    projectId,
    bpId,
    nodeId,
    title,
    desc,
    role,
    direction_type,
    direction_field_title,
    direction_title,
    set_refuse
  ) {
    updateNodeRequest.value = {};
    const req = updateNodeRequest.value;
    req.isLoading = true;
    let description = null;
    if (Object.keys(desc).length) {
      description = desc;
    }
    return axios
      .post(api.nodeById({ projectId, bpId, nodeId }), {
        title,
        description,
        role,
        direction_type,
        direction_field_title,
        direction_title,
        set_refuse
      })
      .then(() => {
        bpNode.value = {};
        getNodeList(projectId, bpId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteNode(projectId, bpId, nodeId) {
    deleteNodeRequest.value = {};
    const req = deleteNodeRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.nodeById({ projectId, bpId, nodeId }))
      .then(() => {
        bpNode.value = {};
        getNodeList(projectId, bpId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getNode(projectId, bpId, nodeId) {
    getNodeRequest.value = {};
    const req = getNodeRequest.value;
    req.isLoading = true;

    return axios
      .get(api.nodeById({ projectId, bpId, nodeId }))
      .then((resp) => {
        bpNode.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteNodeRef(projectId, bpId, nodeDirectionId) {
    deleteNodeRefRequest.value = {};
    const req = deleteNodeRefRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.deattachNode({ projectId, bpId, nodeDirectionId }))
      .then(() => {
        dataForDeleteNodeRef.value = {
          sourceNode: null,
          targetNode: null
        };
        getNodeList(projectId, bpId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function clearRequestVariables() {
    getNodeRequest.value = {};
    deleteNodeRequest.value = {};
    updateNodeRequest.value = {};
    createNodeRequest.value = {};
  }

  return {
    createNode,
    getNodeList,
    updateNode,
    deleteNode,
    getNode,
    clearRequestVariables,
    deleteNodeRef,
    nodeList,
    roleList,
    directionTypeList,
    bpNode,
    getNodeRequest,
    deleteNodeRequest,
    updateNodeRequest,
    createNodeRequest,
    dataForDeleteNodeRef,
    deleteNodeRefRequest,
    isShowSidebar
  };
});
