<script setup lang="js">
    import { ref } from 'vue';
    import useModal from '@/stores/modal.store';
    const emit = defineEmits(['closeMenu']);

    const { openModal } = useModal();
    function callModal(modalName) {
        openModal(modalName)
        emit('closeMenu');
    }
</script>
<template>   
    <div class="context-menu">
        <div class="context-menu_items">
            <div class="context-menu_item" @click="callModal('InputFieldCreateModal')">
                Поле
            </div>
            <div class="context-menu_item" @click="callModal('InputCatalogCreateModal')">
                Справочник
            </div>
            <div class="context-menu_item" @click="callModal('InputTableCreateModal')">
                Таблицу
            </div>
            <div class="context-menu_item" @click="callModal('InputStaticCreateModal')">
                Заголовок
            </div>
        </div>
    </div>
</template>