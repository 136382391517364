import { computed, inject, reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';
import { useLocalStorage } from '@vueuse/core';

export const useAuthStore = defineStore('auth', () => {
  const axios = inject(AXIOS) || baseAxios;

  const token = useLocalStorage('authToken', null);
  const loginRequest = reactive({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const authorized = computed(() => !!token.value);
  const userData = ref({});

  function $reset() {
    loginRequest.isFinished = false;
    loginRequest.isLoading = false;
    loginRequest.error = '';
  }

  async function login(email, password) {
    $reset();
    loginRequest.isLoading = true;

    return axios
      .post(api.login(), { email, password })
      .then((resp) => {
        token.value = resp.data;
        loginRequest.isFinished = true;
        user();
      })
      .catch((e) => {
        loginRequest.error = e.response?.data?.message;
        throw e;
      })
      .finally(() => (loginRequest.isLoading = false));
  }

  function logout() {
    return axios?.post(api.logout()).finally(() => (token.value = null));
  }

  function user() {
    return axios.post(api.me()).then((resp) => {
      userData.value = resp.data;
    });
  }

  return {
    //states
    token,
    loginRequest,
    userData,

    //getters
    authorized,

    //actions
    login,
    logout,
    user
  };
});
