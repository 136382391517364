<script setup lang="js">
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';

const { fixateBP } = useBusinessProcessStore();
const { fixateBPRequest, bp } = storeToRefs(useBusinessProcessStore());
const { closeModal } = useModal();
const projectId = useRouteParams('projectId');

function fixateItem() {
  fixateBP(projectId.value, bp.value.id).then(() => closeModal());
}
function isHasError() {
  if (!(fixateBPRequest.value.error?.errors)) {
    return fixateBPRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="fixateItem">
    <template v-slot:title>Зафиксировать бизнес-процесс</template>
    <template v-slot:body>
      <p v-if="isHasError()" class="error-mesage">
        {{ isHasError() }}
      </p>
    </template>
    <template v-slot:submit>Зафиксировать</template>
  </ModalTemplateSide>
</template>