<script setup lang="js">
import { ref } from 'vue';
import { useAuthStore } from '../stores/auth.store';
import { useRouter } from 'vue-router';

const email = ref('');
const password = ref('');
const router = useRouter();

const authStore = useAuthStore();

function authorize() {
  authStore.login(email.value, password.value).then(() => {
    router.push('/projects');
  });
}
</script>

<template>
  <div class="container login-page">
    <div class="row">
      <div class="col-md-auto">
        <h2>Авторизация</h2>
        <div class="login-data">
          <label for="email">Email:</label>
          <input type="email" v-model.trim="email" />
        </div>
        <div class="login-data">
          <label for="password">Пароль:</label>
          <input type="password" v-model="password" @keyup.enter="authorize" />
        </div>
        <button @click="authorize" class="btn-default">Войти</button>
      </div>
    </div>
  </div>
</template>
