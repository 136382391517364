<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteParams } from '@vueuse/router';
import { useAutoformStore } from '@/stores/autoform.store';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import ContextMenu from '@/components/ContextMenu.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';

const router = useRouter();
const autoformStore = useAutoformStore();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { activeModal, openModal, closeModal } = useModal();
const { autoformList, autoform, deleteAutoformRequest } = storeToRefs(autoformStore);

onMounted(() => {
  autoformStore.getAutoformList(projectId.value, bpId.value, nodeId.value);
});

function goToUpdate(item) {
  router.push({
    name: 'update-autoform', params: {
      projectId: projectId.value,
      bpId: bpId.value,
      nodeId: nodeId.value,
      autoformId: item.id
    }
  });
}
function openDeleteModal(item) {
  autoform.value = item;
  openModal('deleteModal');
}
function doDeleteAutoform() {
  autoformStore.deleteAutoform(projectId.value, bpId.value, nodeId.value, autoform.value.id);
  closeModal();
}
function isDeleteFieldError() {
  return deleteAutoformRequest.value.error;
}
</script>
<template>
  <div class="bp-section-content_links">
    <RouterLink :to="{
      name: 'autoforms',
      params: {
        projectId: projectId,
        bpId: bpId,
        nodeId: nodeId
      }
    }" class="bp-section-content_link">
      Автоформы
    </RouterLink>
  </div>

  <div class="d-flex justify-content-between align-items-center input-btns">
    <RouterLink :to="{
      name: 'create-autoform',
      params: {
        projectId: projectId,
        bpId: bpId,
        nodeId: nodeId
      }
    }" class="add-btn">
      <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
      Создать автоформу
    </RouterLink>
  </div>
  <p v-if="isDeleteFieldError()" class="error-mesage">
    {{ isDeleteFieldError() }}
  </p>
  <div class="table-responsive">
    <table class="table gray-table">
      <thead>
        <tr>
          <th>Наименование</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="autoform in autoformList">
          <td>
            {{ autoform.name }}
          </td>
          <td>
            <ContextMenu :menuItems="['update', 'delete']" @delete="openDeleteModal(autoform)"
              @update="goToUpdate(autoform)" />
          </td>
        </tr>
      </tbody>
    </table>
    <DeleteModal v-if="activeModal === 'deleteModal'" :itemText="'эту автоформу'" @confirmDelete="doDeleteAutoform()" />
  </div>
</template>
