import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';
import { useRouteParams } from '@vueuse/router';
import useModal from '@/stores/modal.store';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useProjectTeamStore = defineStore('projectTeam', () => {
  const axios = inject(AXIOS) || baseAxios;

  const searchName = ref('');
  const sortType = ref('1');
  const teamUserList = ref([]);
  const projectId = useRouteParams('projectId');

  const updateUserListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getTeamUserListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const setUserPermissionRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null,
    userId: null,
    permissionName: null
  });

  function getTeamUserList() {
    getTeamUserListRequest.value = {};
    const req = getTeamUserListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.teamUsers({ projectId: projectId.value }), {
        params: { search: searchName.value }
      })
      .then((resp) => {
        teamUserList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateTeamUserList(user_ids) {
    updateUserListRequest.value = {};
    const req = updateUserListRequest.value;
    req.isLoading = true;

    return axios
      .post(api.teamUsers({ projectId: projectId.value }), {
        user_ids
      })
      .then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          getTeamUserList();
        } else {
          throw new Error(`Request failed with status code ${resp.status}`);
        }
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function setUserTeamPermission(project_id, user_id, permission, permission_state) {
    setUserPermissionRequest.value = {};
    const req = setUserPermissionRequest.value;
    req.isLoading = true;
    req.userId = user_id;
    req.permissionName = permission;

    return axios
      .post(api.permissions(), {
        project_id,
        user_id,
        permission,
        permission_state
      })
      .then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          getTeamUserList();
        } else {
          throw new Error(`Request failed with status code ${resp.status}`);
        }
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
        req.userId = null;
        req.permissionName = null;
      });
  }

  return {
    searchName,
    teamUserList,
    sortType,
    setUserPermissionRequest,
    updateUserListRequest,
    getTeamUserListRequest,
    setUserPermissionRequest,
    updateTeamUserList,
    getTeamUserList,
    setUserTeamPermission
  };
});
