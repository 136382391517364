<script setup lang="js">
import { useProjectStore } from '@/stores/project.store';
import { useAutoformStore } from '@/stores/autoform.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { storeToRefs } from 'pinia';
import useModal from '@/stores/modal.store';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import OutputCreateModal from '@/components/modals/OutputCreateModal.vue';
import OutputUpdateModal from '@/components/modals/OutputUpdateModal.vue';

const autoformStore = useAutoformStore();
const inputGeometriesStore = useInputGeometriesStore();
const projectStore = useProjectStore();
const { inputGeometryList } = storeToRefs(inputGeometriesStore);
const { custom_variables, output_entities, selectedOutputIndex } = storeToRefs(autoformStore);
const inputFieldStore = useInputFieldStore();
const { inputFieldList } = storeToRefs(inputFieldStore);
const { project } = storeToRefs(projectStore);
const { activeModal, closeModal } = useModal();

function fieldName(field) {
  let fieldKeys = Object.keys(field);
  let title = {};
  if (fieldKeys.includes("catalog")) {
    title = field.catalog.title;
  }
  if (fieldKeys.includes("the_table")) {
    title = field.the_table.title;
  }
  if (fieldKeys.includes("static")) {
    title = field.static.title;
  }
  if (fieldKeys.includes("field")) {
    title = field.field.title;
  }
  if (fieldKeys.includes("geometry")) {
    title = field.geometry.title;
  }

  return title[project.value.default_locale] || "";
}

function getFieldName(fieldType, fieldId) {
  let field = null;
  if (fieldType == "input_geometry") {
    field = inputGeometryList.value.find((field) => field.id == fieldId);
  } else {
    field = inputFieldList.value.find((field) => field.id == fieldId);
  }
  
  if (field) {
    return fieldName(field);
  }
}

function getVariableTitle(variableName) {
  let variable = custom_variables.value.find((v) => v.name == variableName);

  return variable.title || "";
}

function openUpdateModal(index) {
  selectedOutputIndex.value = index;
  activeModal.value = "OutputUpdateModal";
}

function openDeleteModal(index) {
  selectedOutputIndex.value = index;
  activeModal.value = "deleteModal";
}

function deleteOutput() {
  output_entities.value.splice(selectedOutputIndex.value, 1);
  closeModal();
}

</script>

<template>
  <div class="autoform-variables">
    <div class="d-flex justify-content-between align-items-center input-btns">
      <div class="add-btn" @click="activeModal = 'OutputCreateModal'">
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Добавить выходные данные
      </div>
    </div>
    <div class="table-responsive">
      <table class="table gray-table">
        <thead>
          <tr>
            <th>Переменная</th>
            <th>Поле (в исполняемой системе)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(output_entity, index) in output_entities" :key="index">
            <td>
              {{ getVariableTitle(output_entity.variable) }}
            </td>
            <td>
              {{ getFieldName(output_entity.entity, output_entity.entity_id) }}
            </td>
            <td>
              <div class="table-buttons">
                <div class="table-button" @click="openUpdateModal(index)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="openDeleteModal(index)">
                  <span class="delite-ico" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <DeleteModal v-if="activeModal === 'deleteModal'" :itemText="'эту строку'" @confirmDelete="deleteOutput()" />
  <OutputCreateModal v-if="activeModal === 'OutputCreateModal'" />
  <OutputUpdateModal v-if="activeModal === 'OutputUpdateModal'" />
</template>
