import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useProjectSubmoduleGroupsStore = defineStore('projectSubmoduleGroups', () => {
  const axios = inject(AXIOS) || baseAxios;

  const projectSubmoduleGroupList = ref([]);

  const getProjectSubmoduleGroupListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const attachProjectSubmoduleGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null,
  });

  const detachProjectSubmoduleGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null,
  });

  function getProjectSubmoduleGroupList(projectId) {
    getProjectSubmoduleGroupListRequest.value = {};
    const req = getProjectSubmoduleGroupListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.projectSubmoduleGroups({ projectId }))
      .then((resp) => {
        projectSubmoduleGroupList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function attachProjectSubmoduleGroup(projectId, submoduleGroupId) {
    attachProjectSubmoduleGroupRequest.value = {};
    const req = attachProjectSubmoduleGroupRequest.value;
    req.isLoading = true;

    return axios
      .post(api.projectSubmoduleGroupById({ projectId, submoduleGroupId }))
      .then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          getProjectSubmoduleGroupList(projectId);
        } else {
          throw new Error(`Request failed with status code ${resp.status}`);
        }
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function detachProjectSubmoduleGroup(projectId, submoduleGroupId) {
    detachProjectSubmoduleGroupRequest.value = {};
    const req = detachProjectSubmoduleGroupRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.projectSubmoduleGroupById({ projectId, submoduleGroupId }))
      .then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          getProjectSubmoduleGroupList(projectId);
        } else {
          throw new Error(`Request failed with status code ${resp.status}`);
        }
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    projectSubmoduleGroupList,
    getProjectSubmoduleGroupListRequest,
    detachProjectSubmoduleGroupRequest,
    attachProjectSubmoduleGroupRequest,
    getProjectSubmoduleGroupList,
    attachProjectSubmoduleGroup,
    detachProjectSubmoduleGroup
  };
});
