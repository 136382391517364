<script setup lang="js">
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import { onMounted } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import ProjectCreateModal from '@/components/modals/ProjectCreateModal.vue';
import useModal from '@/stores/modal.store';
import Search from '@/components/Search.vue';
import Sort from '@/components/Sort.vue';

const projectStore = useProjectStore();
const { activeModal, openModal } = useModal();
const { paginatedProjectList, searchName, sortType } = storeToRefs(projectStore);

onMounted(() => {
  projectStore.getPaginatedProjectList();
});

function changeSortType(newValue) {
  sortType.value = newValue;
  projectStore.getPaginatedProjectList();
}

function handlePageChange(page) {
  projectStore.getPaginatedProjectList(page);
};
</script>
<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <h1 class="mt-4 mb-3 page-header">Проекты</h1>
        <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
          <div class="d-flex align-items-center flex-wrap">
            <Search
              @search="projectStore.getPaginatedProjectList()"
              :value="searchName"
              :placeholderText="'Поиск по названию'"
              @input="searchName = $event.target.value"
            />
            <!-- <Sort :value="sortType" @applyChange="changeSortType" /> -->
          </div>
          <div @click="openModal('projectCreateModal')" class="orange-btn">
            <img src="@/assets/img/plus-ico.svg" alt="" /> Создать проект
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div
            class="projects_item"
            v-for="project in paginatedProjectList.data"
            :key="project.id"
            v-if="paginatedProjectList && paginatedProjectList.data"
          >
            <div>
              <div class="projects_item-name">{{ project.name }}</div>
            </div>
            <div class="d-flex justify-content-between align-items-center flex-content-end">
              <RouterLink :to="`projects/${project.id}/services`" class="projects_item-link"
                ><span
              /></RouterLink>
            </div>
          </div>
        </div>
        <div class="my-3">
          <Bootstrap5Pagination
            :data="paginatedProjectList"
            @pagination-change-page="handlePageChange"
            show-disabled="true"
            :limit="2"
          />
          <ProjectCreateModal v-if="activeModal === 'projectCreateModal'" />
        </div>
      </div>
    </div>
  </div>
</template>
