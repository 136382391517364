<script setup lang="js">
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useLocaleStore } from '@/stores/locale.store';
import { useProjectStore } from '@/stores/project.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const projectStore = useProjectStore();
const localeStore = useLocaleStore();
const { closeModal } = useModal();

const { locales } = storeToRefs(localeStore);
const { createProjectRequest } = storeToRefs(projectStore);

const name = ref('');
const selectedLocales = ref([]);
const defaultLocale = ref('');

function createProject() {
  projectStore.createProject(name.value, selectedLocales.value, defaultLocale.value).then(() => {
    closeModal();
  });
}
function isErrorField(fieldName) {
  if (createProjectRequest.value.error?.errors) {
    return createProjectRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!createProjectRequest.value.error?.errors) {
    return createProjectRequest.value.error;
  }
}
onMounted(() => {
  localeStore.getLocales();
});
</script>

<template>
  <ModalTemplateSide @submit="createProject">
    <template v-slot:title>Создать проект</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Наименование проекта</label>
        <input
          v-model="name"
          type="text"
          class="form-control"
          :class="{ error: isErrorField('name') }"
        />
        <p v-if="isErrorField('name')" class="error-mesage">
          <span v-for="error in isErrorField('name')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Интерфейсные языки</label>
        <div class="d-flex justify-content-between w-50">
          <label v-for="locale in locales" :key="locale.id" class="form-check">
            <input
              type="checkbox"
              v-model="selectedLocales"
              :value="locale.id"
              class="form-check-input"
            />
            <span class="bold-field-name">{{ locale.name }}</span>
          </label>
        </div>
        <p v-if="isErrorField('locales')" class="error-mesage">
          <span v-for="error in isErrorField('locales')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Язык по умолчанию</label>
        <select
          v-model="defaultLocale"
          class="form-select"
          :class="{ error: isErrorField('default_locale') }"
        >
          <option v-for="(locale, key) in selectedLocales" :key="key" :value="locale">
            {{ locale }}
          </option>
        </select>
        <p v-if="isErrorField('default_locale')" class="error-mesage">
          <span v-for="error in isErrorField('default_locale')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>
      <img
        v-if="createProjectRequest.isLoading"
        class="loader"
        src="@/assets/img/loader.gif"
        alt="loader.gif"
      />
      <div v-else>Создать</div>
    </template>
  </ModalTemplateSide>
</template>
