<script setup lang="js">
import { ref } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { createRegTableColumn } = useRegistryStore();
const registryStore = useRegistryStore();
const inputFieldStore = useInputFieldStore();
const { regTables, createRegTableColumnRequest } = storeToRefs(registryStore);
const { typeList } = storeToRefs(inputFieldStore);
const regTableId = ref({});
const description = ref(null);
const name = ref(null);
const type = ref(null);
const is_pk = ref(false);
const is_unique = ref(false);
const is_fk = ref(false);

function createColumn() {
  createRegTableColumn(projectId.value, regTableId.value, description.value, type.value, is_pk.value, is_unique.value, is_fk.value, name.value).then(() => {
    emit('save');
    closeModal();
  });
}
function isErrorField(fieldName) {
  if (createRegTableColumnRequest.value.error?.errors) {
    return createRegTableColumnRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createRegTableColumnRequest.value.error?.errors)) {
    return createRegTableColumnRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createColumn">
    <template v-slot:title>Добавить колонку</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Выберите таблицу</label>
        <select v-model="regTableId" class="form-select">
          <option :value="table.id" v-for="table in regTables">{{ table.id }}</option>
        </select>
      </div>
      <div class="mt-3">
        <label class="form-label">Наименование</label>
        <input type="text" v-model="name" class="form-control" :class="{'error': isErrorField('name')}" />
        <p v-if="isErrorField('name')" class="error-mesage">
          <span v-for="error in isErrorField('name')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Описание</label>
        <input type="text" v-model="description" class="form-control" :class="{'error': isErrorField('description')}" />
        <p v-if="isErrorField('description')" class="error-mesage">
          <span v-for="error in isErrorField('description')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select v-model="type" class="form-select" :class="{'error': isErrorField('type')}">
          <option v-for="typeKey in Object.keys(typeList)" :key="typeKey" :value="typeKey">
            {{ typeList[typeKey] }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Первичный ключ</label>
        <basic-toggle-switch v-model="is_pk" />
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Уникальный ключ</label>
        <basic-toggle-switch v-model="is_unique" />
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Внешний ключ</label>
        <basic-toggle-switch v-model="is_fk" />
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
