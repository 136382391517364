<script setup lang="js">
import { storeToRefs } from 'pinia';
import { defineProps, defineEmits } from 'vue';
import ModalTemplateCenter from '@/components/modals/ModalTemplateCenter.vue';
import { useProjectStore } from '@/stores/project.store';

const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const emit = defineEmits(['confirmDelete']);
const props = defineProps({
  itemText: String,
  title: {
    type: String,
    default: ""
  }
});

function handleSubmit() {
  emit('confirmDelete');
}
</script>
<template>
  <ModalTemplateCenter @submit="$emit('confirmDelete')">
    <template v-slot:title
      >Вы действительно хотите удалить {{ props.itemText }}
      {{ props.title }}?</template
    >

    <template v-slot:submit @click="handleSubmit()">Удалить</template>
  </ModalTemplateCenter>
</template>
