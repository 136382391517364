<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useRegistryStore } from '@/stores/registry.store';
import { useNodeStore } from '@/stores/node.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const registryStore = useRegistryStore();
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { regTables } = storeToRefs(registryStore);
const { createInputCatalogRequest } = storeToRefs(inputFieldStore);
const childNodes = bpNode.value.child_nodes;

const selectedRegTable = ref(null);
const reg_version = ref(null);
const title = ref({});
const title_columns = ref({});
const order = ref(0);
const required = ref(false);
const child_node_ids = ref([]);

onMounted(() => {
  if (!regTables.value.length) {
    registryStore.getRegTableList(projectId.value);
  }
  inputFieldStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
});

function createCatalog() {
  inputFieldStore.createInputCatalog(projectId.value, bpId.value, nodeId.value, selectedRegTable.value.id, selectedRegTable.value.last_version, title.value, title_columns.value, required.value, child_node_ids.value)
    .then(() => {
      closeModal();
    });
}

function getTitle(title) {
  return title[project.value.default_locale]
}
function isErrorField(fieldName) {
  if (createInputCatalogRequest.value.error?.errors) {
    return createInputCatalogRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createInputCatalogRequest.value.error?.errors)) {
    return createInputCatalogRequest.value.error;
  }
}
function convertIdToString(id) {
  return id.toString();
}
</script>

<template>
  <ModalTemplateSide @submit="createCatalog">
    <template v-slot:title>Добавить справочник</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование поля({{ locale.name }})</label>
        <input type="text" v-model="title[locale.id]" class="form-control" :class="{ 'error': isErrorField('title') }" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Реестр</label>
        <select v-model="selectedRegTable" class="form-select" :class="{ 'error': isErrorField('type') }">
          <template v-for="registry in regTables" :key="registry.id">
            <option v-if="registry.stable" :value="registry" >
              {{ registry.id }}
            </option>
          </template>
        </select>
        <p v-if="isErrorField('reg_table_id')" class="error-mesage">
          <span v-for="error in isErrorField('reg_table_id')">{{ error }}</span>
        </p>
      </div>
      <!-- 
        <div class="mt-3">
        <label class="form-label">Версия реестра</label>
        <select v-model="reg_version" class="form-select" :class="{'error': isErrorField('type')}">
          <option v-for="version in registryVersionList" :key="version.id" :value="version.id">
            {{ version }}
          </option>
        </select>
        <p v-if="isErrorField('reg_version')" class="error-mesage">
          <span v-for="error in isErrorField('reg_version')">{{ error }}</span>
        </p>
      </div> -->
      <template v-if="selectedRegTable">
        <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
          <label class="form-label">Колонка({{ locale.name }})</label>
          <select v-model="title_columns[locale.id]" class="form-select"
            :class="{ 'error': isErrorField('title_columns') }">
            <option v-for="column in selectedRegTable.columns" :key="column.id" :value="convertIdToString(column.id)">
              {{ column.name }}
            </option>
          </select>
        </div>
      </template>
      <p v-if="isErrorField('title_columns')" class="error-mesage">
        <span v-for="error in isErrorField('title_columns')">{{ error }}</span>
      </p>
      <!-- <div class="mt-3">
        <label class="form-label">Порядок</label>
        <input type="number" v-model="order" class="form-control" min="0"/>
      </div> -->
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Обязательность</label>
        <basic-toggle-switch v-model="required" />
      </div>
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <select v-model="child_node_ids" class="form-select" multiple
          :class="{ 'error': isErrorField('child_node_ids') }">
          <option v-for="node in childNodes" :key="node.id" :value="node.id">
            {{ getTitle(bpNode.current.node.title) }}
          </option>
        </select>
        <p v-if="isErrorField('child_node_ids')" class="error-mesage">
          <span v-for="error in isErrorField('child_node_ids')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
