<script setup lang="js">
import { ref } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { deleteRegTableColumn } = useRegistryStore();
const registryStore = useRegistryStore();
const { regTables, deleteRegTableColumnRequest } = storeToRefs(registryStore);
const regTable = ref(null);
const columnId = ref(null);

function deleteColumn() {
  deleteRegTableColumn(projectId.value, regTable.value.id, columnId.value).then(() => {
    emit('save');
    closeModal();
  });
}
function isHasError() {
  if (!(deleteRegTableColumnRequest.value.error?.errors)) {
    return deleteRegTableColumnRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="deleteColumn">
    <template v-slot:title>Удалить колонку</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Выберите таблицу</label>
        <select v-model="regTable" class="form-select">
          <option :value="regTable" v-for="regTable in regTables">{{ regTable.id }}</option>
        </select>
      </div>
      <div class="mt-3" v-if="regTable">
        <label class="form-label">Выберите столбец</label>
        <select v-model="columnId" class="form-select">
          <option :value="column.id" v-for="column in regTable.columns">{{ column.name }}</option>
        </select>
      </div>
      <p v-if="isHasError()" class="error-mesage">
        {{ isHasError() }}
      </p>
    </template>
    <template v-slot:submit>Удалить</template>
  </ModalTemplateSide>
</template>
