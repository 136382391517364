<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRouter } from 'vue-router';
import { useProjectStore } from '@/stores/project.store';
import { useAutoformStore } from '@/stores/autoform.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { storeToRefs } from 'pinia';
import AutoformVariables from '@/components/Autoform/Variables.vue';
import AutoformOperations from '@/components/Autoform/Operations.vue';
import AutoformOutputs from '@/components/Autoform/Outputs.vue';

const autoformStore = useAutoformStore();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { name, instruction_steps, custom_variables, output_entities, createAutoformRequest } = storeToRefs(autoformStore);
const activeTab = ref('variables');
const router = useRouter();
const inputGeometriesStore = useInputGeometriesStore();
const { inputGeometryList } = storeToRefs(inputGeometriesStore);
const inputFieldStore = useInputFieldStore();
const { inputFieldList } = storeToRefs(inputFieldStore);
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);

onMounted(() => {
    autoformStore.getOperationList(projectId.value, bpId.value, nodeId.value);
    autoformStore.getVariableList(projectId.value, bpId.value, nodeId.value);
    if (!inputFieldList.value.length) {
        inputFieldStore.getInputFieldList(projectId.value, bpId.value, nodeId.value);
    }
    if (!inputGeometryList.value.length) {
        inputGeometriesStore.getInputGeometriesList(projectId.value, bpId.value, nodeId.value);
    }
    if (!project.value) {
        projectStore.getProject(projectId.value);
    }
});

function createAutoform() {
    autoformStore.createAutoform(projectId.value, bpId.value, nodeId.value, name.value, instruction_steps.value, custom_variables.value, output_entities.value).then(() =>
        router.push(
            {
                name: 'autoforms',
                params: {
                    projectId: projectId.value,
                    bpId: bpId.value,
                    nodeId: nodeId.value
                }
            }
        )
    )
}
function isErrorField() {
    if (createAutoformRequest.value.error?.errors) {
        return createAutoformRequest.value.error.errors || false;
    }
}
function isNotFieldError() {
    if (!(createAutoformRequest.value.error?.errors)) {
        return createAutoformRequest.value.error;
    }
}
</script>
<template>
    <div class="container-fluid">
        <div class="page-header d-flex mt-3 align-items-center">
            <RouterLink :to="{
                name: 'autoforms', params: {
                    projectId: projectId,
                    bpId: bpId,
                    nodeId: nodeId
                }
            }" class="back-btn" />
            <h1 class="mb-0">Создание автоформы</h1>
        </div>
    </div>
    <div class="autoform-page d-flex">
        <div class="autoform_sidebar">
            <div class="bp-section-content_link" :class="{ 'router-link-active': activeTab == 'variables' }"
                @click="activeTab = 'variables'">
                1. Переменные
            </div>
            <div class="bp-section-content_link" :class="{ 'router-link-active': activeTab == 'operations' }"
                @click="activeTab = 'operations'">
                2. Операции
            </div>
            <div class="bp-section-content_link" :class="{ 'router-link-active': activeTab == 'outputs' }"
                @click="activeTab = 'outputs'">
                3. Выходные данные
            </div>
            <div class="px-3 mt-3">
                <div class="orange-btn w-100" @click="createAutoform()" :disabled="createAutoformRequest.isLoading">
                    Сохранить
                </div>
            </div>
            <p v-if="isErrorField()" class="error-mesage">
                <span v-for="error in isErrorField()">{{ error }}</span>
            </p>
            <p v-if="isNotFieldError()" class="error-mesage">
                {{ isNotFieldError() }}
            </p>
        </div>
        <div class="autoform_content" :class="{ 'gray-bg': activeTab == 'operations' }">
            <AutoformVariables v-if="activeTab == 'variables'" />
            <AutoformOperations v-if="activeTab == 'operations'" />
            <AutoformOutputs v-if="activeTab == 'outputs'" />
        </div>
    </div>
</template>