<script setup lang="js">
import { useRoute } from 'vue-router';
const route = useRoute();
</script>
<template>
  <div class="bp-section-content_links">
    <RouterLink
      :to="{
        name: 'input-fields',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_link"
    >
      Заполняемые
    </RouterLink>
    <RouterLink
      :to="{
        name: 'view-fields',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_link"
    >
      Просматриваемые
    </RouterLink>
  </div>
  <router-view />
</template>
