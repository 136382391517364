<script setup>
import { ref } from 'vue';
const props = defineProps({
  value: String
});
const emit = defineEmits(['applyChange']);
let isListOpen = ref(false);
const types = ref({
  1: 'сначала новые',
  2: 'сначала старые'
});
function changeType(newType) {
  isListOpen = !isListOpen;
  emit('applyChange', newType);
}
</script>
<template>
  <div class="sort-block d-flex">
    <div>
      Сортировка:
      <span>
        {{ types[value] }}
        <img
          src="@/assets/img/sort-list-ico.svg"
          alt=""
          @click="isListOpen = !isListOpen"
          :class="{ isListOpen }"
        />
      </span>
    </div>
    <div v-show="isListOpen" class="sort-type-list">
      <div
        v-for="typeKey in Object.keys(types)"
        v-show="value != typeKey"
        @click="changeType(typeKey)"
      >
        {{ types[typeKey] }}
      </div>
    </div>
  </div>
</template>
