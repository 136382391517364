<script setup lang="js">
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useSubmoduleStore } from '@/stores/submodule.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const submoduleStore = useSubmoduleStore();
const { closeModal } = useModal();

const { updateSubmoduleGroupRequest, group } = storeToRefs(submoduleStore);

const name = ref(null);
const description = ref(null);

function updateGroup() {
  submoduleStore.updateSubmoduleGroup(group.value.id, name.value, description.value).then(() => {
    closeModal();
  });
}
function isErrorField(fieldName) {
  if (updateSubmoduleGroupRequest.value.error?.errors) {
    return updateSubmoduleGroupRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!updateSubmoduleGroupRequest.value.error?.errors) {
    return updateSubmoduleGroupRequest.value.error;
  }
}
onMounted(() => {
  if (group.value) {
    name.value = group.value.name;
    description.value = group.value.description;
  }
});
</script>

<template>
  <ModalTemplateSide @submit="updateGroup">
    <template v-slot:title>Редактировать группу</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Наименование</label>
        <input v-model="name" type="text" class="form-control" :class="{ error: isErrorField('name') }" />
        <p v-if="isErrorField('name')" class="error-mesage">
          <span v-for="error in isErrorField('name')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Описание</label>
        <textarea v-model="description" class="form-control" :class="{ error: isErrorField('description') }"></textarea>
        <p v-if="isErrorField('description')" class="error-mesage">
          <span v-for="error in isErrorField('description')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        <span v-for="error in isNotFieldError()">{{ error }}</span>
      </p>
    </template>
    <template v-slot:submit>
      <img v-if="updateSubmoduleGroupRequest.isLoading" class="loader" src="@/assets/img/loader.gif" alt="loader.gif" />
      <div v-else>Сохранить</div>
    </template>
  </ModalTemplateSide>
</template>
