<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref, defineProps } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRouter } from 'vue-router';

const router = useRouter();
const { closeModal } = useModal();
const projectStore = useProjectStore();
const inputDocumentsStore = useInputDocumentsStore();
const { inputDocumentsUploadTemplateRequest, inputDocumentsDownloadTemplateRequest, inputDocumentsDownloadBasicTemplateRequest, inputDocument } = storeToRefs(inputDocumentsStore);
const { project } = storeToRefs(projectStore);
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');
const props = defineProps({
  selectedDocument: Object
});
const templates = ref({});
const reuploadedTemplates = ref([]);

onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
});

async function removeTemplate(localeId) {
  let doc = inputDocument.value.templates.find((el) => el.locale === localeId);
  await inputDocumentsStore.deleteTemplate(
    projectId.value,
    bpId.value,
    nodeId.value,
    inputDocument.value.id,
    doc.id
  );
}
async function upload(event, localeId) {
  const file = event.target.files[0];
  templates.value[localeId] = file;
  const fd = new FormData();
  fd.append('template', file);
  fd.append('locale', localeId);

  await inputDocumentsStore.uploadTemplate(
    projectId.value,
    bpId.value,
    nodeId.value,
    inputDocument.value.id,
    fd
  );
}
function downloadTemplate(localeId) {
  let doc = inputDocument.value.templates.find((el) => el.locale === localeId);
  inputDocumentsStore.downloadTemplate(
    projectId.value,
    bpId.value,
    nodeId.value,
    inputDocument.value.id,
    doc.id
  );
}

function downloadBasicTemplate() {
  inputDocumentsStore.downloadBasicTemplate(
    projectId.value,
    bpId.value,
    nodeId.value
  );
}

function docInTemplates(localeId) {
  if (inputDocument.value.templates.some((el) => el.locale === localeId)) {
    let doc = inputDocument.value.templates.find((el) => el.locale === localeId);
    return doc.path;
  } else {
    return false;
  }
}
function isHasUploadError() {
  if (!(inputDocumentsUploadTemplateRequest.value.error?.errors)) {
    return inputDocumentsUploadTemplateRequest.value.error;
  }
}
function isHasDownloadError() {
  if (!(inputDocumentsDownloadTemplateRequest.value.error?.errors)) {
    return inputDocumentsDownloadTemplateRequest.value.error;
  }
}
function isHasDownloadBasicTemplateError() {
  if (!(inputDocumentsDownloadBasicTemplateRequest.value.error?.errors)) {
    return inputDocumentsDownloadBasicTemplateRequest.value.error;
  }
}

</script>

<template>
  <ModalTemplateSide @submit="closeModal()" :hideButtons="true">
    <template v-slot:title>Шаблоны документа</template>
    <template v-slot:body>
      <div class="orange-btn d-flex align-items-center mt-3 mb-3" @click="downloadBasicTemplate()">
        <img src="@/assets/img/download-ico.svg" alt="download-ico.svg" class="pe-2" />
        Скачать первичный шаблон
      </div>
      <p v-if="isHasDownloadBasicTemplateError()" class="error-mesage">
        {{ isHasDownloadBasicTemplateError() }}
      </p>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <div class="form-label">Шаблон документа на {{ locale.name }}</div>
        <label v-if="docInTemplates(locale.id)" class="form-control d-flex align-items-center justify-content-between">
          <div class="cursor-pointer doc-template d-flex align-items-center justify-content-between"
            @click="downloadTemplate(locale.id)">
            <img src="@/assets/img/template-ico.svg" alt="template-ico.svg" class="pe-3" />
            <span class="doc-template_name">{{ docInTemplates(locale.id) }}</span>
          </div>
          <img src="@/assets/img/context-menu_delete-ico.svg" alt="context-menu_delete-ico.svg"
            class="ps-2 cursor-pointer" @click="removeTemplate(locale.id)" />
        </label>
        <div v-else>
          <label class="upload-file">
            <input type="file" @change="upload($event, locale.id)" />
            <div class="blue-text pb-1">Выберите файл для загрузки</div>
            <div class="gray-text">Поддерживаемые форматы: DOCX. Максимальный размер: 2МБ.</div>
          </label>
        </div>
      </div>
      <p v-if="isHasUploadError()" class="error-mesage">
        {{ isHasUploadError() }}
      </p>
      <p v-if="isHasDownloadError()" class="error-mesage">
        {{ isHasDownloadError() }}
      </p>
    </template>
    <template v-slot:submit></template>
  </ModalTemplateSide>
</template>
