<script setup lang="js">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAutonodeStore } from '@/stores/autonode.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
const emit = defineEmits(['createStep']);

const autonodeStore = useAutonodeStore();
const { operationList, instruction_steps, custom_variables, variableList, selectedInstructionStep, newUuid } = storeToRefs(autonodeStore);
const { closeModal } = useModal();

const id = ref(0);
const operation = ref(null);
const inputVarList = ref(null);
const outputVarList = ref(null);
const input_vars = ref({});
const output_vars = ref({});
const condition = ref(false);
const condition_var = ref(null);

async function getUuid() {
  const response = await autonodeStore.generateUuid();
  return newUuid.value;
}
async function generateUuid() {
  let uuid;
  let isUnique = false;

  while (!isUnique) {
    uuid = await getUuid();
    isUnique = !instruction_steps.value.some(step => step.id === uuid);
  }

  return uuid;
}
async function createStep() {
  id.value = await generateUuid();
  let step = {
    id: id.value,
    operation_id: operation.value.id,
    input_vars: input_vars.value,
    output_vars: output_vars.value,
    parent_id: null
  };
  let children = [];
  let cond = null;
  let firstChildId = null;
  let secondChildId = null;
  firstChildId = await generateUuid();
  if (condition.value) {
    secondChildId = await generateUuid();
    children.push(secondChildId);
    cond = {};
    cond.var = condition_var.value;
    cond.true_step_id = firstChildId;
    cond.false_step_id = secondChildId;
  }
  step.condition = cond;
  children.push(firstChildId);
  step.children = children;
  instruction_steps.value.push(step);
  instruction_steps.value.push({
    id: firstChildId,
    parent_id: id.value,
    children: []
  })
  if (condition.value) {
    instruction_steps.value.push({
      id: secondChildId,
      parent_id: id.value,
      children: []
    })
  }
  emit('createStep');
  closeModal();
}

function selectOperation() {
  inputVarList.value = operation.value.input_args;
  outputVarList.value = operation.value.output_args;
  input_vars.value = {};
  output_vars.value = {};
}
</script>

<template>
  <ModalTemplateSide @submit="createStep">
    <template v-slot:title>Создать операцию</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Операция</label>
        <select v-model="operation" class="form-select" @change="selectOperation()">
          <option v-for="op in operationList" :value="op">
            {{ op.title }}
          </option>
        </select>
      </div>
      <div class="operation-modal_section-header">
        Входные данные
      </div>
      <div class="mt-3" v-for="(inputVar, index) in inputVarList" :key="index">
        <label class="form-label">{{ inputVar.title }}</label>
        <select v-model="input_vars[inputVar.name]" class="form-select">
          <template v-for="variable in variableList">
            <option :value="variable.name" v-if="inputVar.type == variable.type">
              {{ variable.title }}
            </option>
          </template>
          <template v-for="variable in custom_variables">
            <option :value="variable.name" v-if="inputVar.type == variable.type">
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
      <div class="operation-modal_section-header">
        Выходные данные
      </div>
      <div class="mt-3" v-for="(outputVar, index) in outputVarList" :key="index">
        <label class="form-label">{{ outputVar.title }}</label>
        <select v-model="output_vars[outputVar.name]" class="form-select">
          <template v-for="variable in variableList">
            <option :value="variable.name" v-if="outputVar.type == variable.type">
              {{ variable.title }}
            </option>
          </template>
          <template v-for="variable in custom_variables">
            <option :value="variable.name" v-if="outputVar.type == variable.type">
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
      <div class="operation-modal_section-header">&nbsp;</div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Условие</label>
        <basic-toggle-switch v-model="condition" />
      </div>
      <div class="mt-3" v-if="condition">
        <label class="form-label">Переменная для условия</label>
        <select v-model="condition_var" class="form-select">
          <template v-for="variable in variableList">
            <option :value="variable.name" v-if="variable.type == 'boolean'">
              {{ variable.title }}
            </option>
          </template>
          <template v-for="variable in custom_variables">
            <option :value="variable.name">
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
