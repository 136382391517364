import { ref } from 'vue';

const activeModal = ref('');

function openModal(value) {
  activeModal.value = value;
}
function closeModal() {
  activeModal.value = null;
}

export default function useModal() {
  return {
    activeModal,
    openModal,
    closeModal
  };
}
