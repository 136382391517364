<script setup lang="js">
import { ref } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { updateRegTableColumn } = useRegistryStore();
const registryStore = useRegistryStore();
const inputFieldStore = useInputFieldStore();
const { regTables } = storeToRefs(registryStore);
const { typeList } = storeToRefs(inputFieldStore);
const regTable = ref({});
const regTableColumn = ref({});
const description = ref(null);
const name = ref(null);
const type = ref(null);
const is_pk = ref(false);
const is_unique = ref(false);
const is_fk = ref(false);

function createColumn() {
  updateRegTableColumn(projectId.value, regTable.value.id, regTableColumn.value.id, description.value, type.value, is_pk.value, is_unique.value, is_fk.value, name.value).then(() => {
    emit('save');
    closeModal();
  });
}

function selectColumn(){
  name.value = regTableColumn.value.name;
  description.value = regTableColumn.value.description;
  type.value = regTableColumn.value.type;
  is_pk.value = regTableColumn.value.is_pk;
  is_unique.value = regTableColumn.value.is_unique;
  is_fk.value = regTableColumn.value.is_fk;
}
</script>

<template>
  <ModalTemplateSide @submit="createColumn">
    <template v-slot:title>Добавить колонку</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Выберите таблицу</label>
        <select v-model="regTable" class="form-select">
          <option :value="table" v-for="table in regTables">{{ table.id }}</option>
        </select>
      </div>
      <div class="mt-3" v-if="regTable.id">
        <label class="form-label">Выберите столбец</label>
        <select v-model="regTableColumn" class="form-select" @change="selectColumn">
          <option :value="column" v-for="column in regTable.columns">{{ column.name }}</option>
        </select>
      </div>
      <template v-if="regTableColumn.id">
        <div class="mt-3">
          <label class="form-label">Наименование</label>
          <input type="text" v-model="name" class="form-control" />
        </div>
        <div class="mt-3">
          <label class="form-label">Описание</label>
          <input type="text" v-model="description" class="form-control" />
        </div>
        <div class="mt-3">
          <label class="form-label">Тип</label>
          <select v-model="type" class="form-select">
            <option v-for="typeKey in Object.keys(typeList)" :key="typeKey" :value="typeKey">
              {{ typeList[typeKey] }}
            </option>
          </select>
        </div>
        <div class="mt-3 d-flex align-items-center justify-content-between">
          <label class="form-label">Первичный ключ</label>
          <basic-toggle-switch v-model="is_pk" />
        </div>
        <div class="mt-3 d-flex align-items-center justify-content-between">
          <label class="form-label">Уникальный ключ</label>
          <basic-toggle-switch v-model="is_unique" />
        </div>
        <div class="mt-3 d-flex align-items-center justify-content-between">
          <label class="form-label">Внешний ключ</label>
          <basic-toggle-switch v-model="is_fk" />
        </div>
      </template>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
