import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useSubmoduleStore = defineStore('submodule', () => {
  const axios = inject(AXIOS) || baseAxios;

  const submoduleGroupList = ref([]);
  const endSubmoduleList = ref([]);
  const commonSubmoduleList = ref([]);
  const endSubmoduleTypeList = ref({
    ui: 'ui',
    api: 'api'
  });
  const group = ref(null);
  const endSubmodule = ref(null);
  const commonSubmodule = ref(null);

  const getSubmoduleGroupListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getEndSubmoduleListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getCommonSubmoduleListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createSubmoduleGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateSubmoduleGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteSubmoduleGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getSubmoduleGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createEndSubmoduleRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateEndSubmoduleRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteEndSubmoduleRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getEndSubmoduleRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createCommonSubmoduleRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateCommonSubmoduleRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteCommonSubmoduleRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getCommonSubmoduleRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getSubmoduleGroups() {
    getSubmoduleGroupListRequest.value = {};
    const req = getSubmoduleGroupListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.submoduleGroups())
      .then((resp) => {
        submoduleGroupList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createSubmoduleGroup(name, description) {
    createSubmoduleGroupRequest.value = {};
    const req = createSubmoduleGroupRequest.value;
    req.isLoading = true;
    return axios
      .post(api.submoduleGroups(), {
        name,
        description
      })
      .then(() => {
        getSubmoduleGroups();
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateSubmoduleGroup(submoduleGroupId, name, description) {
    updateSubmoduleGroupRequest.value = {};
    const req = updateSubmoduleGroupRequest.value;
    req.isLoading = true;
    return axios
      .post(api.submoduleGroupById({ submoduleGroupId }), {
        name,
        description
      })
      .then(() => {
        getSubmoduleGroups();
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteSubmoduleGroup(submoduleGroupId) {
    deleteSubmoduleGroupRequest.value = {};
    const req = deleteSubmoduleGroupRequest.value;
    req.isLoading = true;
    return axios
      .delete(api.submoduleGroupById({ submoduleGroupId }))
      .then(() => {
        getSubmoduleGroups();
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getSubmoduleGroup(submoduleGroupId) {
    getSubmoduleGroupRequest.value = {};
    const req = getSubmoduleGroupRequest.value;
    req.isLoading = true;
    return axios
      .get(api.submoduleGroupById({ submoduleGroupId }))
      .then((resp) => {
        group.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getEndSubmodules(submoduleGroupId) {
    getEndSubmoduleListRequest.value = {};
    const req = getEndSubmoduleListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.endSubmodules({ submoduleGroupId }))
      .then((resp) => {
        endSubmoduleList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createEndSubmodule(submoduleGroupId, name, description, type, url) {
    createEndSubmoduleRequest.value = {};
    const req = createEndSubmoduleRequest.value;
    req.isLoading = true;

    return axios
      .post(api.endSubmodules({ submoduleGroupId }), {
        name,
        description,
        type,
        url
      })
      .then(() => {
        getEndSubmodules(submoduleGroupId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateEndSubmodule(submoduleGroupId, endSubmoduleId, name, description, type, url) {
    updateEndSubmoduleRequest.value = {};
    const req = updateEndSubmoduleRequest.value;
    req.isLoading = true;

    return axios
      .post(api.endSubmoduleById({ submoduleGroupId, endSubmoduleId }), {
        name,
        description,
        type,
        url
      })
      .then(() => {
        getEndSubmodules(submoduleGroupId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteEndSubmodule(submoduleGroupId, endSubmoduleId) {
    deleteEndSubmoduleRequest.value = {};
    const req = deleteEndSubmoduleRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.endSubmoduleById({ submoduleGroupId, endSubmoduleId }))
      .then(() => {
        getEndSubmodules(submoduleGroupId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getEndSubmodule(submoduleGroupId, endSubmoduleId) {
    getEndSubmoduleRequest.value = {};
    const req = getEndSubmoduleRequest.value;
    req.isLoading = true;

    return axios
      .get(api.endSubmoduleById({ submoduleGroupId, endSubmoduleId }))
      .then((resp) => {
        endSubmodule.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getCommonSubmodules(submoduleGroupId) {
    getCommonSubmoduleListRequest.value = {};
    const req = getCommonSubmoduleListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.commonSubmodules({ submoduleGroupId }))
      .then((resp) => {
        commonSubmoduleList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createCommonSubmodule(submoduleGroupId, fd) {
    createCommonSubmoduleRequest.value = {};
    const req = createCommonSubmoduleRequest.value;
    req.isLoading = true;

    return axios
      .post(api.commonSubmodules({ submoduleGroupId }), 
        fd
      )
      .then(() => {
        getCommonSubmodules(submoduleGroupId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateCommonSubmodule(submoduleGroupId, сommonSubmoduleId, fd) {
    updateCommonSubmoduleRequest.value = {};
    const req = updateCommonSubmoduleRequest.value;
    req.isLoading = true;

    return axios
      .post(api.commonSubmoduleById({ submoduleGroupId, сommonSubmoduleId }), fd)
      .then(() => {
        getCommonSubmodules(submoduleGroupId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteCommonSubmodule(submoduleGroupId, commonSubmoduleId) {
    deleteCommonSubmoduleRequest.value = {};
    const req = deleteCommonSubmoduleRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.commonSubmoduleById({ submoduleGroupId, commonSubmoduleId }))
      .then(() => {
        getCommonSubmodules(submoduleGroupId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getCommonSubmodule(submoduleGroupId, commonSubmoduleId) {
    getCommonSubmoduleRequest.value = {};
    const req = getCommonSubmoduleRequest.value;
    req.isLoading = true;

    return axios
      .get(api.commonSubmoduleById({ submoduleGroupId, commonSubmoduleId }))
      .then((resp) => {
        commonSubmodule.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    submoduleGroupList,
    group,
    endSubmoduleList,
    endSubmoduleTypeList,
    endSubmodule,
    commonSubmoduleList,
    commonSubmodule,
    getSubmoduleGroupListRequest,
    createSubmoduleGroupRequest,
    updateSubmoduleGroupRequest,
    deleteSubmoduleGroupRequest,
    getSubmoduleGroupRequest,
    getEndSubmoduleListRequest,
    createEndSubmoduleRequest,
    updateEndSubmoduleRequest,
    deleteEndSubmoduleRequest,
    getEndSubmoduleRequest,
    getCommonSubmoduleListRequest,
    createCommonSubmoduleRequest,
    updateCommonSubmoduleRequest,
    deleteCommonSubmoduleRequest,
    getCommonSubmoduleRequest,
    getSubmoduleGroups,
    createSubmoduleGroup,
    updateSubmoduleGroup,
    deleteSubmoduleGroup,
    getSubmoduleGroup,
    getEndSubmodules,
    createEndSubmodule,
    updateEndSubmodule,
    deleteEndSubmodule,
    getEndSubmodule,
    getCommonSubmodules,
    createCommonSubmodule,
    updateCommonSubmodule,
    deleteCommonSubmodule,
    getCommonSubmodule
  };
});
