<script setup lang="js">
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRoute } from 'vue-router';
import useModal from '@/stores/modal.store';
import { useNodeStore } from '@/stores/node.store';
import { useProjectStore } from '@/stores/project.store';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import InputGeometryCreateModal from '@/components/modals/InputGeometryCreateModal.vue';
import InputGeometryEditModal from '@/components/modals/InputGeometryEditModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';

const route = useRoute();
const { activeModal, openModal, closeModal } = useModal();
const nodeStore = useNodeStore();
const projectStore = useProjectStore();
const { bpNode } = storeToRefs(nodeStore);
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const inputGeometriesStore = useInputGeometriesStore();
const { inputGeometryList, inputGeometriesChangeOrderRequest, inputGeometryDeleteRequest } = storeToRefs(inputGeometriesStore);
const { project } = storeToRefs(projectStore);
const selectedGeom = ref(null);
const openMenu = ref({});
const orderInputGeom = ref(false);
const originalGeometriesOrder = ref([]);

onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputGeometriesStore.clearRequestVariables();
  inputGeometriesStore.getInputGeometriesList(projectId.value, bpId.value, nodeId.value);
  if (Object.keys(bpNode.value).length == 0) {
    nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
  }
});
function getTitle(geom) {
  return geom?.title[project.value.default_locale];
}
function fieldType(type) {
  const typeMapping = {
    Polygon: 'Полигон',
    LineString: 'Линия'
  };
  return typeMapping[type] || 'Точка';
}
function toggleMenu(id) {
  for (const key in openMenu.value) {
    if (key !== id.toString()) {
      openMenu.value[key] = false;
    }
  }
  openMenu.value[id] = !openMenu.value[id];
}
function openMenuModal(modalName, geomField) {
  activeModal.value = modalName;
  selectedGeom.value = geomField;
}
function deleteGeom() {
  inputGeometriesStore.inputGeomDelete(projectId.value, bpId.value, nodeId.value, selectedGeom.value.id);
  closeModal();
}
function toggleOrderState() {
  if (!orderInputGeom.value) {
    originalGeometriesOrder.value = [...inputGeometryList.value];
  }
  orderInputGeom.value = !orderInputGeom.value;
}
function cancel() {
  inputGeometryList.value = [...originalGeometriesOrder.value];
  orderInputGeom.value = false;
}
function saveOrderState() {
  orderInputGeom.value = false;
  const geometriesOrder = inputGeometryList.value.map((geom, index) => ({
    id: geom.id,
    order: index + 1
  }));

  inputGeometriesStore.inputGeometriesChangeOrder(
    projectId.value,
    bpId.value,
    nodeId.value,
    geometriesOrder
  );
}
function selectForChangeOrder(index) {
  if (orderInputGeom.value) {
    if (selectedGeom.value === null) {
      selectedGeom.value = index;
    } else {
      const selectedItem = inputGeometryList.value[selectedGeom.value];
      inputGeometryList.value.splice(selectedGeom.value, 1);
      inputGeometryList.value.splice(index, 0, selectedItem);
      selectedGeom.value = null;

      inputGeometryList.value.forEach((geom, idx) => {
        geom.order = idx + 1;
      });
    }
  }
}
function isChangeOrderError() {
  return inputGeometriesChangeOrderRequest.value.error;
}
function isDeleteFieldError() {
  return inputGeometryDeleteRequest.value.error;
}
</script>

<template>
  <div v-if="route.name === 'input-geometry'" class="docs-view">
    <div class="input-btns d-flex justify-content-between align-items-center">
      <span v-if="orderInputGeom">НАСТРОИТЬ ПОРЯДОК ГЕОМЕТРИИ</span>
      <div v-else class="add-btn" @click="openModal('inputGeometryCreateModal')">
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Добавить геометрию
      </div>
      <div class="input-btns_order d-flex">
        <RouterLink :to="{
          name: 'input-geometries-child',
          params: {
            projectId: projectId,
            bpId: bpId,
            nodeId: nodeId
          }
        }" v-if="!orderInputGeom" class="order-btn me-4">
          Дочерние узлы
        </RouterLink>

        <div v-if="orderInputGeom" class="d-flex">
          <div class="gray-btn me-3" @click="cancel()">Отмена</div>
          <div class="orange-btn" @click="saveOrderState">Сохранить изменения</div>
        </div>

        <div v-else class="order-btn" @click="toggleOrderState">Настроить порядок</div>
      </div>
    </div>
    <p v-if="isChangeOrderError()" class="error-mesage">
      {{ isChangeOrderError() }}
    </p>
    <p v-if="isDeleteFieldError()" class="error-mesage">
      {{ isDeleteFieldError() }}
    </p>
    <div class="table-responsive">
      <table class="table gray-table">
        <thead>
          <tr>
            <th>Наименование геометрии</th>
            <th>Тип</th>
            <th>Инструмент</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(inputGeometry, index) in inputGeometryList" :key="inputGeometry.id"
            :class="{ selectedDoc: selectedGeom === index }">
            <td>
              <img v-if="orderInputGeom" src="@/assets/img/order-dots-ico.svg" alt="order-dots-ico.svg"
                @click="selectForChangeOrder(index)" :class="{ hidden: orderInputGeom && selectedGeom === index }" />
              <img v-if="orderInputGeom && selectedGeom === index" src="@/assets/img/order-dots-ico_hover.svg"
                alt="order-dots-ico_hover.svg" />
              <span class="name">{{ getTitle(inputGeometry.geometry) }}</span>
            </td>
            <td>{{ fieldType(inputGeometry.geometry.type) }}</td>
            <td>{{ inputGeometry.geometry.settings }}</td>
            <td class="imgs" @click="toggleMenu(inputGeometry.id)">
              <img src="@/assets/img/context-menu-ico.svg" alt="context-menu-ico.svg" class="img" />
              <img src="@/assets/img/context-menu-ico-hover.svg" alt="context-menu-ico.svg" class="img-hover" />
              <div v-if="openMenu[inputGeometry.id]" class="menu">
                <div class="mb-2" @click="openMenuModal('inputGeometryEditModal', inputGeometry)">
                  <img src="@/assets/img/edit-ico.svg" alt="edit-ico.svg" class="pe-2" />Редактировать
                </div>
                <div @click="openMenuModal('deleteModal', inputGeometry)">
                  <img src="@/assets/img/context-menu_delete-ico.svg" alt="context-menu_delete-ico"
                    class="pe-2" />Удалить
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <InputGeometryCreateModal v-if="activeModal === 'inputGeometryCreateModal'" />
    <InputGeometryEditModal v-if="activeModal === 'inputGeometryEditModal'" :selectedGeom="selectedGeom" />
    <DeleteModal v-if="activeModal === 'deleteModal'" :itemText="'геометрию'" :title="getTitle(selectedGeom.geometry)"
      @confirmDelete="deleteGeom()" />
  </div>
  <router-view />
</template>
