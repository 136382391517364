<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { defineProps } from 'vue';

const { closeModal } = useModal();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const { inputFieldList, changeDirectionalsRequest } = storeToRefs(inputFieldStore);
const savedFields = ref({
  fields: [],
  tables: [],
  catalogs: [],
  statics: []
});
const originalSavedFields = ref({
  fields: [],
  tables: [],
  catalogs: [],
  statics: []
});
const selectedFieldsForSent = ref({
  fields: {
    creating_ids: [],
    removing_ids: []
  },
  tables: {
    creating_ids: [],
    removing_ids: []
  },
  catalogs: {
    creating_ids: [],
    removing_ids: []
  },
  statics: {
    creating_ids: [],
    removing_ids: []
  }
});
const selectAllChecked = ref(false);
const props = defineProps({
  selectedChildBpNodeId: Number,
  selectedChildPivotId: Number
});

function toggleField(field) {
  let fieldId = field.id;
  let type = fieldType(field);
  let fieldIndexInForSentCreating = selectedFieldsForSent.value[type].creating_ids.findIndex((f) => f == fieldId);
  let fieldIndexInForSentRemoving = selectedFieldsForSent.value[type].removing_ids.findIndex((f) => f == fieldId);
  let fieldIndexInSaved = savedFields.value[type].findIndex((f) => f == fieldId);
  let fieldIndexInSavedOriginal = originalSavedFields.value[type].findIndex((f) => f == fieldId);
  if (isCheckedField(field)) {
    if (fieldIndexInSaved != -1) {
      if (fieldIndexInSavedOriginal != -1) {
        selectedFieldsForSent.value[type].removing_ids.push(fieldId)
      }
      savedFields.value[type].splice(fieldIndexInSaved, 1)
    }
    if (fieldIndexInForSentCreating != -1) {
      selectedFieldsForSent.value[type].creating_ids.splice(fieldIndexInForSentCreating, 1)
    }
  } else {
    if (fieldIndexInForSentCreating == -1) {
      selectedFieldsForSent.value[type].creating_ids.push(fieldId);
    }
    if (fieldIndexInForSentRemoving != -1) {
      selectedFieldsForSent.value[type].removing_ids.splice(fieldIndexInForSentRemoving, 1)
    }
  }
}
function fieldType(field) {
  let fieldKeys = Object.keys(field);
  if (fieldKeys.includes("catalog")) {
    return 'catalogs';
  }
  if (fieldKeys.includes("the_table")) {
    return 'tables';
  }
  if (fieldKeys.includes("static")) {
    return 'statics';
  }
  if (fieldKeys.includes("field")) {
    return 'fields';
  }
}
function selectAll() { }
function fieldName(field) {
  let fieldKeys = Object.keys(field);
  let title = {};
  if (fieldKeys.includes("catalog")) {
    title = field.catalog.title
  }
  if (fieldKeys.includes("the_table")) {
    title = field.the_table.title
  }
  if (fieldKeys.includes("static")) {
    title = field.static.title
  }
  if (fieldKeys.includes("field")) {
    title = field.field.title
  }
  return title[project.value.default_locale];
}
function isSavedField(field) {
  return field.directions.some((direction) => direction.next_bp_node_id == props.selectedChildBpNodeId)
}
function isCheckedField(field) {
  let fieldId = field.id;
  let type = fieldType(field);
  let creating_ids = selectedFieldsForSent.value[type].creating_ids;
  if (creating_ids.includes(fieldId) || savedFields.value[type].includes(fieldId)) {
    return true
  } else {
    return false
  }
}
function saveChanges() {
  inputFieldStore.formInputChangeDirectionals(
    projectId.value,
    bpId.value,
    nodeId.value,
    props.selectedChildPivotId,
    selectedFieldsForSent.value.fields,
    selectedFieldsForSent.value.tables,
    selectedFieldsForSent.value.catalogs,
    selectedFieldsForSent.value.statics
  ).then(() => {
    closeModal();
  });
}
function isRequestError() {
  return changeDirectionalsRequest.value.error;
}
onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  let sFields = inputFieldList.value.filter((inputField) => isSavedField(inputField));
  sFields.forEach((field) => {
    let fieldId = field.id;
    let type = fieldType(field);
    let fieldIndex = savedFields.value[type].findIndex((f) => f == fieldId);
    if (fieldIndex == -1) {
      originalSavedFields.value[type].push(fieldId);
      savedFields.value[type].push(fieldId);
    }
  })
});
</script>

<template>
  <ModalTemplateSide @submit="saveChanges">
    <template v-slot:title>Добавить поля для дочернего узла</template>
    <template v-slot:body>
      <!-- 
      <div class="d-flex justify-content-between mt-4 mb-4">
        <div class="form-label d-flex align-items-center">
          <input type="checkbox" class="me-3 modal-checkbox" v-model="selectAllChecked" @change="selectAll" />Выделить все
        </div>
      </div> -->
      <p v-if="isRequestError()" class="error-mesage">
        {{ isRequestError() }}
      </p>
      <div class="modal-nodes-list">
        <div class="d-flex mb-3 mt-4" v-for="inputField in inputFieldList" :key="inputField.id">
          <div class="checkbox-btn" :class="{ checked: isCheckedField(inputField) }" @click="toggleField(inputField)" />
          <span>{{ fieldName(inputField) }} {{ inputField.id }}</span>
        </div>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
