import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useInputGeometriesStore = defineStore('input_geometries', () => {
  const axios = inject(AXIOS) || baseAxios;

  const inputGeometryList = ref([]);
  const ancestorNodesList = ref([]);
  const viewGeometryList = ref([]);

  const getInputGeometryListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createInputGeometryRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputGeometryEditRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputGeometryDeleteRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getAncestorNodesListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputGeometriesChangeOrderRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputGeometriesChangeDirectionalRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getViewGeometryListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createViewGeometryRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const viewGeometriesChangeOrderRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getInputGeometriesList(projectId, bpId, nodeId) {
    getInputGeometryListRequest.value = {};
    const req = getInputGeometryListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.inputGeometries({ projectId, bpId, nodeId }))
      .then((resp) => {
        inputGeometryList.value = resp.data.sort((a, b) => a.order - b.order);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createInputGeometry(
    projectId,
    bpId,
    nodeId,
    title,
    settings = null,
    type,
    order,
    child_node_ids
  ) {
    createInputGeometryRequest.value = {};
    const req = createInputGeometryRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputGeometries({ projectId, bpId, nodeId }), {
        title,
        settings,
        type,
        order,
        child_node_ids
      })
      .then(() => {
        getInputGeometriesList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function inputGeomUpdate(projectId, bpId, nodeId, inputGeomId, title, type, settings = null) {
    inputGeometryEditRequest.value = {};
    const req = inputGeometryEditRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputGeometriesById({ projectId, bpId, nodeId, inputGeomId }), {
        title,
        type,
        settings
      })
      .then(() => {
        getInputGeometriesList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function inputGeomDelete(projectId, bpId, nodeId, inputGeomId) {
    inputGeometryDeleteRequest.value = {};
    const req = inputGeometryDeleteRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.inputGeometriesById({ projectId, bpId, nodeId, inputGeomId }))
      .then(() => {
        getInputGeometriesList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function getAncestorNodesList(projectId, bpId, nodeId) {
    getAncestorNodesListRequest.value = {};
    const req = getAncestorNodesListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.inputGeometriesAncestors({ projectId, bpId, nodeId }))
      .then((resp) => {
        ancestorNodesList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function inputGeometriesChangeOrder(projectId, bpId, nodeId, geometries) {
    inputGeometriesChangeOrderRequest.value = {};
    const req = inputGeometriesChangeOrderRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputGeometriesOrder({ projectId, bpId, nodeId }), { geometries })
      .then(() => {
        getInputGeometriesList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function changeDirectionalGeometries(projectId, bpId, nodeId, directionId, geoms) {
    inputGeometriesChangeDirectionalRequest.value = {};
    const req = inputGeometriesChangeDirectionalRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputGeometriesDirectional({ projectId, bpId, nodeId, directionId }), {
        geoms
      })
      .then(() => {
        getInputGeometriesList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getViewGeometriesList(projectId, bpId, nodeId) {
    getViewGeometryListRequest.value = {};
    const req = getViewGeometryListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.viewGeometries({ projectId, bpId, nodeId }))
      .then((resp) => {
        viewGeometryList.value = resp.data.sort((a, b) => a.order - b.order);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createViewGeometries(
    projectId,
    bpId,
    nodeId,
    creating_input_geom_ids,
    removing_input_geom_ids
  ) {
    createViewGeometryRequest.value = {};
    const req = createViewGeometryRequest.value;
    req.isLoading = true;

    return axios
      .post(api.viewGeometries({ projectId, bpId, nodeId }), {
        creating_input_geom_ids,
        removing_input_geom_ids
      })
      .then(() => {
        getViewGeometriesList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function viewGeometriesChangeOrder(projectId, bpId, nodeId, geometries) {
    viewGeometriesChangeOrderRequest.value = {};
    const req = viewGeometriesChangeOrderRequest.value;
    req.isLoading = true;

    return axios
      .post(api.viewGeometriesOrder({ projectId, bpId, nodeId }), { geometries })
      .then(() => {
        getViewGeometriesList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function clearRequestVariables() {
    viewGeometriesChangeOrderRequest.value = {};
    createViewGeometryRequest.value = {};
    getViewGeometryListRequest.value = {};
    inputGeometriesChangeDirectionalRequest.value = {};
    inputGeometriesChangeOrderRequest.value = {};
    getAncestorNodesListRequest.value = {};
    inputGeometryDeleteRequest.value = {};
    inputGeometryEditRequest.value = {};
    createInputGeometryRequest.value = {};
    getInputGeometryListRequest.value = {};
  }

  return {
    inputGeometryList,
    ancestorNodesList,
    viewGeometryList,
    viewGeometriesChangeOrderRequest,
    createViewGeometryRequest,
    getViewGeometryListRequest,
    inputGeometriesChangeDirectionalRequest,
    inputGeometriesChangeOrderRequest,
    getAncestorNodesListRequest,
    inputGeometryDeleteRequest,
    inputGeometryEditRequest,
    createInputGeometryRequest,
    getInputGeometryListRequest,
    getInputGeometriesList,
    createInputGeometry,
    inputGeomUpdate,
    inputGeomDelete,
    getAncestorNodesList,
    inputGeometriesChangeOrder,
    changeDirectionalGeometries,
    getViewGeometriesList,
    createViewGeometries,
    viewGeometriesChangeOrder,
    clearRequestVariables
  };
});
