<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useUserStore } from '@/stores/user.store';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import useModal from '@/stores/modal.store';
import AdminProjectCreateModal from '@/components/modals/AdminProjectCreateModal.vue';
import Search from '@/components/Search.vue';

const userStore = useUserStore();
const { usersProjectList, user, searchName, changePermissionsRequest } = storeToRefs(userStore);
const userId = useRouteParams('userId');
const { activeModal, openModal } = useModal();

onMounted(() => {
  userStore.getUser(userId.value);
  userStore.getUsersProjectList(userId.value);
});

function hasPermission(project, permission) {
  return project.permissions.includes(project.name + '.' + permission);
}

function togglePermission(project, permission, isChecked) {
  const permissionString = project.name + '.' + permission;
  if (isChecked) {
    if (!project.permissions.includes(permissionString)) {
      project.permissions.push(permissionString);
    }
  } else {
    project.permissions = project.permissions.filter((p) => p !== permissionString);
  }
  userStore.changePermissions(project.id, +userId.value, permission, isChecked);
}
</script>
<template>
  <div class="container-fluid">
    <div class="page-header d-flex mt-3 align-items-center mb-3">
      <RouterLink to="/admin" class="back-btn" />
      <h1>{{ user.name }}</h1>
    </div>
    <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
      <div class="d-flex align-items-center flex-wrap">
        <Search
          @search="userStore.getUsersProjectList(userId)"
          :value="searchName"
          :placeholderText="'Поиск проекта'"
          @input="searchName = $event.target.value"
        />
        <!-- <Sort :value="sortType" @applyChange="changeSortType" /> -->
      </div>
      <div @click="openModal('addProjectModal')" class="orange-btn">
        <img src="@/assets/img/plus-ico.svg" alt="" /> Доступные проекты
      </div>
    </div>
    <div class="table-responsive mt-4">
      <table class="table gray-table gray-table_with-border">
        <thead>
          <tr>
            <th>Проект</th>
            <th>Редактирование</th>
            <th>Фиксация</th>
            <th>Тестовая заливка</th>
            <th>Боевая заливка</th>
            <th>Управление командой</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in usersProjectList" :key="project.id">
            <td>{{ project.name }}</td>
            <td>
              <img
                v-if="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.projectId === project.id &&
                  changePermissionsRequest.permissionName === 'project_update'
                "
                class="loader"
                src="@/assets/img/loader.gif"
                alt="loader.gif"
              />
              <input
                v-else
                type="checkbox"
                class="table-checkbox"
                :checked="hasPermission(project, 'project_update')"
                @change="togglePermission(project, 'project_update', $event.target.checked)"
                :disabled="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.permissionName !== 'project_update'
                "
              />
            </td>
            <td>
              <img
                v-if="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.projectId === project.id &&
                  changePermissionsRequest.permissionName === 'project_fixation'
                "
                class="loader"
                src="@/assets/img/loader.gif"
                alt="loader.gif"
              />
              <input
                v-else
                type="checkbox"
                class="table-checkbox"
                :checked="hasPermission(project, 'project_fixation')"
                @change="togglePermission(project, 'project_fixation', $event.target.checked)"
                :disabled="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.permissionName !== 'project_fixation'
                "
              />
            </td>
            <td>
              <img
                v-if="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.projectId === project.id &&
                  changePermissionsRequest.permissionName === 'project_deploy_test'
                "
                class="loader"
                src="@/assets/img/loader.gif"
                alt="loader.gif"
              />
              <input
                v-else
                type="checkbox"
                class="table-checkbox"
                :checked="hasPermission(project, 'project_deploy_test')"
                @change="togglePermission(project, 'project_deploy_test', $event.target.checked)"
                :disabled="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.permissionName !== 'project_deploy_test'
                "
              />
            </td>
            <td>
              <img
                v-if="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.projectId === project.id &&
                  changePermissionsRequest.permissionName === 'project_deploy_prod'
                "
                class="loader"
                src="@/assets/img/loader.gif"
                alt="loader.gif"
              />
              <input
                v-else
                type="checkbox"
                class="table-checkbox"
                :checked="hasPermission(project, 'project_deploy_prod')"
                @change="togglePermission(project, 'project_deploy_prod', $event.target.checked)"
                :disabled="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.permissionName !== 'project_deploy_prod'
                "
              />
            </td>
            <td>
              <img
                v-if="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.projectId === project.id &&
                  changePermissionsRequest.permissionName === 'project_team_management'
                "
                class="loader"
                src="@/assets/img/loader.gif"
                alt="loader.gif"
              />
              <input
                v-else
                type="checkbox"
                class="table-checkbox"
                :checked="hasPermission(project, 'project_team_management')"
                @change="
                  togglePermission(project, 'project_team_management', $event.target.checked)
                "
                :disabled="
                  changePermissionsRequest.isLoading &&
                  changePermissionsRequest.permissionName !== 'project_team_management'
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AdminProjectCreateModal v-if="activeModal === 'addProjectModal'" />
  </div>
</template>
