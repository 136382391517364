<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useViewFieldStore } from '@/stores/viewField.store';
import { useProjectStore } from '@/stores/project.store';
import useModal from '@/stores/modal.store';
import { useNodeStore } from '@/stores/node.store';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import ContextMenu from '@/components/ContextMenu.vue';
import ManageViewFieldsModal from '@/components/modals/ManageViewFieldsModal.vue';

const viewFieldStore = useViewFieldStore();
const nodeStore = useNodeStore();
const projectStore = useProjectStore();
const { activeModal } = useModal();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { project } = storeToRefs(projectStore);
const { viewFieldList, typeList, changeFormViewOrderRequest } = storeToRefs(viewFieldStore);
const { bpNode } = storeToRefs(nodeStore);
const selectedField = ref(null);
const isChangeOrderActive = ref(false);
const isChangeOrderMode = ref(false);
const selectedFieldIndex = ref(null);

function fieldType(field) {
  let fieldKeys = Object.keys(field);
  if (fieldKeys.includes("input_catalog")) {
    return "Справочник";
  }
  if (fieldKeys.includes("input_table")) {
    return "Таблица";
  }
  if (fieldKeys.includes("input_static")) {
    return "Статичное поле";
  }
  if (fieldKeys.includes("input_field")) {
    return typeList.value[field.input_field.field.type];
  }
}

function fieldName(field) {
  let fieldKeys = Object.keys(field);
  let title = {};
  if (fieldKeys.includes("input_catalog")) {
    title = field.input_catalog.catalog.title;
  }
  if (fieldKeys.includes("input_table")) {
    title = field.input_table.the_table.title;
  }
  if (fieldKeys.includes("input_static")) {
    title = field.input_static.static.title;
  }
  if (fieldKeys.includes("input_field")) {
    title = field.input_field.field.title;
  }
  return title[project.value.default_locale];
}

function selectItem(index) {
  if (!isChangeOrderMode.value) {
    selectedFieldIndex.value = index;
    isChangeOrderMode.value = true;
  } else {
    const selectedItem = viewFieldList.value[selectedFieldIndex.value];
    viewFieldList.value.splice(selectedFieldIndex.value, 1);
    viewFieldList.value.splice(index, 0, selectedItem);
    isChangeOrderMode.value = false;
    selectedFieldIndex.value = null;
  }
}

function saveOrder() {
  viewFieldList.value.forEach((field, index) => {
    field.order = index
  });
  let fields = [];
  let tables = [];
  let catalogs = [];
  let statics = [];
  const originalFields = viewFieldList.value.filter((inputField) => "input_field" in inputField);
  const originalTables = viewFieldList.value.filter((inputField) => "input_table" in inputField);
  const originalCatalogs = viewFieldList.value.filter((inputField) => "input_catalog" in inputField);
  const originalStatics = viewFieldList.value.filter((inputField) => "input_static" in inputField);
  originalFields.forEach((item) => {
    fields.push({
      id: item.id,
      order: item.order
    })
  });
  originalTables.forEach((item) => {
    tables.push({
      id: item.id,
      order: item.order
    })
  });
  originalCatalogs.forEach((item) => {
    catalogs.push({
      id: item.id,
      order: item.order
    })
  });
  originalStatics.forEach((item) => {
    statics.push({
      id: item.id,
      order: item.order
    })
  });
  isChangeOrderMode.value = false;
  selectedFieldIndex.value = null;
  isChangeOrderActive.value = false;
  viewFieldStore.changeFormViewOrder(projectId.value, bpId.value, nodeId.value, fields, tables, catalogs, statics);
}
function isChangeOrderError() {
  return changeFormViewOrderRequest.value.error;
}
onMounted(() => {
  viewFieldStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  viewFieldStore.getViewFieldList(projectId.value, bpId.value, nodeId.value);
  if (Object.keys(bpNode.value).length == 0) {
    nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
  }
});
</script>
<template>
  <div class="d-flex align-items-center justify-content-between input-fields__btns">
    <div class="input-fields__add-field-btn">
      <button class="simple-btn mt-3 mb-3 ml-3" @click="activeModal = 'ManageViewFieldsModal'">
        <span class="orange-plus" />
        Добавить поле
      </button>
    </div>
    <div class="d-flex align-items-center justify-content-end flex-wrap mr-3">
      <template v-if="isChangeOrderActive == false">
        <div class="simple-underline-btn" @click="isChangeOrderActive = true">Настроить порядок</div>
      </template>
      <template v-else>
        <div class="gray-btn" @click="isChangeOrderActive = false" :disabled="changeFormViewOrderRequest.isLoading">Отмена
        </div>
        <div class="orange-btn" @click="saveOrder()" :disabled="changeFormViewOrderRequest.isLoading">Сохранить изменения
        </div>
      </template>
    </div>
  </div>
  <p v-if="isChangeOrderError()" class="error-mesage">
    {{ isChangeOrderError() }}
  </p>
  <div class="table-responsive pb-5">
    <table class="table gray-table">
      <thead>
        <tr>
          <th>Наименование поля</th>
          <th>Тип</th>
          <th>Обязательность</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(viewField, rowIndex) in viewFieldList" :key="rowIndex"
          :class="{ 'order-selected': selectedFieldIndex == rowIndex }">
          <td>
            <div class="d-flex align-items-center">
              <span class="columns-settings_drag-btn mt-0" @click="selectItem(rowIndex)"
                v-show="isChangeOrderActive == true" />
              <span class="name">{{ fieldName(viewField) }}</span>
            </div>
          </td>
          <td>{{ fieldType(viewField) }}</td>
          <td>
            <template v-if="viewField.required">
              Да
            </template>
            <template v-else>
              Нет
            </template>
          </td>
          <td>
            <!-- <ContextMenu :menuItems="['delete']" @delete="openDeleteModal(viewField)" /> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ManageViewFieldsModal v-if="activeModal === 'ManageViewFieldsModal'" />
</template>