<script setup lang="js">
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import AdminUserCreateModal from '@/components/modals/AdminUserCreateModal.vue';
import AdminUserEditModal from '@/components/modals/AdminUserEditModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import useModal from '@/stores/modal.store';
import Search from '@/components/Search.vue';
import { useUserStore } from '@/stores/user.store';

const userStore = useUserStore();
const { activeModal, openModal, closeModal } = useModal();
const selectedUser = ref(null);
const { paginatedUsersList, searchName } = storeToRefs(userStore);

onMounted(() => {
  userStore.getPaginatedUsersList();
});

function deleteUser() {
  userStore.userDelete(selectedUser.value.id).then(() => {
    closeModal();
  });
}
function openModals(modalName, user) {
  activeModal.value = modalName;
  selectedUser.value = user;
}
function handlePageChange(page) {
  userStore.getPaginatedUsersList(page);
};
</script>

<template>
  <div class="container-fluid admin-view">
    <div class="row">
      <h1 class="mt-4 mb-3 page-header">Администрирование</h1>
      <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
        <div class="d-flex align-items-center flex-wrap">
          <Search
            @search="userStore.getPaginatedUsersList()"
            :value="searchName"
            :placeholderText="'Поиск пользователя'"
            @input="searchName = $event.target.value"
          />
          <!-- <Sort :value="sortType" @applyChange="changeSortType" /> -->
        </div>
        <div @click="openModal('addUserModal')" class="orange-btn">
          <img src="@/assets/img/plus-ico.svg" alt="" /> Добавить пользователя
        </div>
      </div>
      <div class="table-responsive mt-4">
        <table class="table gray-table">
          <thead>
            <tr>
              <th>Имя пользователя</th>
              <th>E-mail</th>
              <th>Доступ к проектам</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in paginatedUsersList.data" :key="user.id">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <RouterLink class="blue-text" :to="`/admin/${user.id}`">
                  {{ user.projects_count }} проектов</RouterLink
                >
              </td>
              <td class="table-buttons">
                <div class="table-button" @click="openModals('editUserModal', user)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="openModals('deleteModal', user)">
                  <span class="delite-ico" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="my-3">
        <Bootstrap5Pagination
          :data="paginatedUsersList"
          @pagination-change-page="handlePageChange"
          show-disabled="true"
          :limit="2"
        />
      </div>
    </div>
    <AdminUserCreateModal v-if="activeModal === 'addUserModal'" />
    <AdminUserEditModal v-if="activeModal === 'editUserModal'" :selectedUser="selectedUser" />
    <DeleteModal
      v-if="activeModal === 'deleteModal'"
      :itemText="'пользователя'"
      :title="selectedUser.name"
      @confirmDelete="deleteUser()"
    />
  </div>
</template>
