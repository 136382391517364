import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useServiceStore = defineStore('service', () => {
  const axios = inject(AXIOS) || baseAxios;

  const searchName = ref('');
  const sortType = ref('1');
  const service = ref({});
  const paginatedServiceList = ref([]);
  const selectedServices = ref([]);
  const isSelectAll = ref(false);
  const serviceRouteList = ref([]);
  const serviceCriterions = ref({});
  const serviceNodeList = ref([]);
  const nodeOrganizations = ref([]);
  const selectedNodeId = ref('');
  const selectedOrg = ref('');

  const getServiceListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createServiceRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteServiceRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getServiceRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getServiceCriterionRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getServiceRouteListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createRouteRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteRouteRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const changeBPInRouteRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getNodeListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const changeNodeOrganizationRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  function getPaginatedServiceList(projectId, page = 1) {
    getServiceListRequest.value = {};
    const req = getServiceListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.services({ projectId }), {
        params: { page }
      })
      .then((resp) => {
        paginatedServiceList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createService(projectId, title, type, criterions, restricted) {
    createServiceRequest.value = {};
    const req = createServiceRequest.value;
    req.isLoading = true;

    return axios
      .post(api.services({ projectId }), {
        title,
        type,
        criterions,
        restricted
      })
      .then(() => {
        getPaginatedServiceList(projectId, 1);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteService(projectId, serviceId) {
    deleteServiceRequest.value = {};
    const req = deleteServiceRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.serviceById({ projectId, serviceId }))
      .then(() => {
        getPaginatedServiceList(projectId, 1);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getService(serviceId, projectId) {
    getServiceRequest.value = {};
    const req = getServiceRequest.value;
    req.isLoading = true;

    return axios
      .get(api.serviceById({ projectId, serviceId }))
      .then((resp) => {
        service.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function getServiceCriterion(projectId, serviceId) {
    getServiceCriterionRequest.value = {};
    const req = getServiceCriterionRequest.value;
    req.isLoading = true;

    return axios
      .get(api.serviceCriterion({ projectId, serviceId }))
      .then((resp) => {
        serviceCriterions.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getServiceRouteList(projectId, serviceId, options) {
    getServiceRouteListRequest.value = {};
    const req = getServiceRouteListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.serviceRoute({ projectId, serviceId }), { params: { options } })
      .then((resp) => {
        serviceRouteList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createRoute(projectId, serviceId, options, businessProcess) {
    createRouteRequest.value = {};
    const req = createRouteRequest.value;
    req.isLoading = true;
    return axios
      .post(api.serviceRoute({ projectId, serviceId }), {
        options,
        bp_id: businessProcess
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function changeBPInRoute(projectId, serviceId, routeId, bpId) {
    changeBPInRouteRequest.value = {};
    const req = changeBPInRouteRequest.value;
    req.isLoading = true;

    return axios
      .post(api.serviceRouteById({ projectId, serviceId, routeId }), {
        bp_id: bpId
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteRoute(projectId, serviceId, routeId) {
    deleteRouteRequest.value = {};
    const req = deleteRouteRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.serviceRouteById({ projectId, serviceId, routeId }))
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getNodeList(projectId, serviceId, routeId) {
    getNodeListRequest.value = {};
    const req = getNodeListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.serviceRouteNodes({ projectId, serviceId, routeId }))
      .then((resp) => {
        serviceNodeList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function changeNodeOrganizations(projectId, serviceId, routeId, bpNodeId, bin) {
    changeNodeOrganizationRequest.value = {};
    const req = changeNodeOrganizationRequest.value;
    req.isLoading = true;
    return axios
      .post(api.nodeOrganizations({ projectId, serviceId, routeId, bpNodeId }), {
        bin
      })
      .then((resp) => {
        nodeOrganizations.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  return {
    searchName,
    sortType,
    paginatedServiceList,
    selectedServices,
    isSelectAll,
    service,
    serviceRouteList,
    serviceCriterions,
    serviceNodeList,
    nodeOrganizations,
    selectedNodeId,
    selectedOrg,
    getServiceListRequest,
    createServiceRequest,
    getServiceRequest,
    getServiceCriterionRequest,
    getServiceRouteListRequest,
    createRouteRequest,
    getNodeListRequest,
    changeNodeOrganizationRequest,
    deleteServiceRequest,
    deleteRouteRequest,
    changeBPInRouteRequest,
    getServiceRouteList,
    getServiceCriterion,
    createRoute,
    getNodeList,
    changeNodeOrganizations,
    getPaginatedServiceList,
    createService,
    getService,
    deleteService,
    deleteRoute,
    changeBPInRoute,
  };
});
