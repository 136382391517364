<script setup lang="js">
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import SubmoduleGroupCreateModal from '@/components/modals/SubmoduleGroupCreateModal.vue';
import SubmoduleGroupUpdateModal from '@/components/modals/SubmoduleGroupUpdateModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import useModal from '@/stores/modal.store';
import { useSubmoduleStore } from '@/stores/submodule.store';

const submoduleStore = useSubmoduleStore();
const { activeModal, openModal, closeModal } = useModal();
const { submoduleGroupList, group } = storeToRefs(submoduleStore);

onMounted(() => {
  submoduleStore.getSubmoduleGroups();
});

function deleteGroup() {
  submoduleStore.deleteSubmoduleGroup(group.value.id).then(() => {
    closeModal();
  });
}
function openModals(modalName, item) {
  activeModal.value = modalName;
  group.value = item;
}
</script>

<template>
  <div class="container-fluid admin-view">
    <div class="row">
      <h1 class="mt-4 mb-3 page-header">Группы субмодулей</h1>
      <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
        <div class="d-flex align-items-center flex-wrap">
        </div>
        <div @click="openModal('submoduleGroupCreateModal')" class="orange-btn">
          <img src="@/assets/img/plus-ico.svg" alt="" /> Создать группу
        </div>
      </div>
      <div class="table-responsive mt-4">
        <table class="table gray-table">
          <thead>
            <tr>
              <th>Название</th>
              <th>Описание</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in submoduleGroupList" :key="item.id">
              <td>
                <RouterLink :to="
                  {
                    name: 'submodule-group-id',
                    params: {
                      submoduleGroupId: item.id
                    }
                  }"
                >
                  {{ item.name }}
                </RouterLink>
              </td>
              <td>{{ item.description }}</td>
              <td class="table-buttons">
                <div class="table-button" @click="openModals('submoduleGroupUpdateModal', item)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="openModals('submoduleGroupDeleteModal', item)">
                  <span class="delite-ico" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <DeleteModal v-if="activeModal === 'submoduleGroupDeleteModal'" :itemText="'группу'"
      @confirmDelete="deleteGroup()" />
    <SubmoduleGroupCreateModal v-if="activeModal === 'submoduleGroupCreateModal'" />
    <SubmoduleGroupUpdateModal v-if="activeModal === 'submoduleGroupUpdateModal'" />
  </div>
</template>
