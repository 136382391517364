<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectSubmoduleGroupsStore } from '@/stores/projectSubmoduleGroups.store';
import { useSubmoduleStore } from '@/stores/submodule.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';

const submoduleStore = useSubmoduleStore();
const projectSubmoduleGroupStore = useProjectSubmoduleGroupsStore();
const projectId = useRouteParams('projectId');
const { submoduleGroupList } = storeToRefs(submoduleStore);
const {
  projectSubmoduleGroupList,
  detachProjectSubmoduleGroupRequest,
  attachProjectSubmoduleGroupRequest
} = storeToRefs(projectSubmoduleGroupStore);
const { getProjectSubmoduleGroupList, attachProjectSubmoduleGroup, detachProjectSubmoduleGroup } = useProjectSubmoduleGroupsStore();

onMounted(() => {
  submoduleStore.getSubmoduleGroups();
  getProjectSubmoduleGroupList(projectId.value);
});

function toggleGroup(submoduleGroupId) {
  let isAttached = projectSubmoduleGroupList.value.some((group => group.id == submoduleGroupId));
  if (isAttached) {
    detachProjectSubmoduleGroup(projectId.value, submoduleGroupId);
  } else {
    attachProjectSubmoduleGroup(projectId.value, submoduleGroupId);
  }
}
function isHasError() {
  return detachProjectSubmoduleGroupRequest.value.error || attachProjectSubmoduleGroupRequest.value.error;
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <p v-if="isHasError()" class="error-mesage">
        {{ isHasError() }}
      </p>
      <div class="table-responsive">
        <table class="table gray-table">
          <thead>
            <tr>
              <th>Субмодуль</th>
              <th>Описание</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in submoduleGroupList">
              <td>
                {{ item.name }}
              </td>
              <td>{{ item.description }}</td>
              <td>
                <img v-if="detachProjectSubmoduleGroupRequest.isLoading || attachProjectSubmoduleGroupRequest.isLoading" class="loader"
                  src="@/assets/img/loader.gif" alt="loader.gif" />
                <span v-else
                  @click="toggleGroup(item.id)"
                  class="table-checkbox" :class="{ active: projectSubmoduleGroupList.some((group => group.id == item.id)) }" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
