<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import { useNodeStore } from '@/stores/node.store';

const { closeModal } = useModal();
const projectStore = useProjectStore();
const inputDocumentsStore = useInputDocumentsStore();
const { project } = storeToRefs(projectStore);
const { inputDocumentsList, createInputDocumentsRequest } = storeToRefs(inputDocumentsStore);
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);
const childNodes = bpNode.value.child_nodes;
const title = ref({});
const maxSize = ref('');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');
const switchValueSign = ref(false);
const switchValueResult = ref(false);
const switchValueRequired = ref(false);
const maxSizes = [
  { id: null, text: '' },
  { id: 1, value: 10 },
  { id: 2, value: 50 },
  { id: 3, value: 100 }
];
const child_node_ids = ref([]);

onMounted(() => {
  inputDocumentsStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
});
function getTitle(title) {
  return title[project.value.default_locale];
}
function create() {
  inputDocumentsStore
    .createInputDocuments(
      projectId.value,
      bpId.value,
      nodeId.value,
      title.value,
      switchValueResult.value,
      maxSize.value,
      switchValueRequired.value,
      switchValueSign.value,
      inputDocumentsList.value.length + 1,
      child_node_ids.value
    )
    .then(() => {
      closeModal();
    });
}
function isErrorField(fieldName) {
  if (createInputDocumentsRequest.value.error?.errors) {
    return createInputDocumentsRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createInputDocumentsRequest.value.error?.errors)) {
    return createInputDocumentsRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="create">
    <template v-slot:title>Добавить документ для заполнения</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование документа на {{ locale.name }}</label>
        <input type="text" v-model="title[locale.id]" class="form-control" :class="{'error': isErrorField('title')}" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Обязательность</span>
        <basic-toggle-switch v-model="switchValueRequired" />
      </div>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Подпись</span>
        <basic-toggle-switch v-model="switchValueSign" />
      </div>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Отображать результат Завителю</span>
        <basic-toggle-switch v-model="switchValueResult" />
      </div>
      <div class="mt-3">
        <label class="form-label">Максимальный размер</label>
        <select v-model="maxSize" class="form-select" :class="{'error': isErrorField('max_size')}">
          <option v-for="size in maxSizes" :key="size.id" :value="size.id">
            {{ size.value }}
          </option>
        </select>
        <p v-if="isErrorField('max_size')" class="error-mesage">
          <span v-for="error in isErrorField('max_size')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <select v-model="child_node_ids" class="form-select" multiple :class="{'error': isErrorField('child_node_ids')}">
          <option v-for="node in childNodes" :key="node.id" :value="node.current.id">
            {{ getTitle(node.current.node.title) }}
          </option>
        </select>
        <p v-if="isErrorField('child_node_ids')" class="error-mesage">
          <span v-for="error in isErrorField('child_node_ids')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
