<script setup lang="js">
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import EndSubmoduleCreateModal from '@/components/modals/EndSubmoduleCreateModal.vue';
import EndSubmoduleUpdateModal from '@/components/modals/EndSubmoduleUpdateModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import useModal from '@/stores/modal.store';
import { useSubmoduleStore } from '@/stores/submodule.store';

const submoduleStore = useSubmoduleStore();
const submoduleGroupId = useRouteParams('submoduleGroupId');
const { activeModal, openModal, closeModal } = useModal();
const { endSubmoduleList, endSubmoduleTypeList, endSubmodule } = storeToRefs(submoduleStore);

onMounted(() => {
  submoduleStore.getEndSubmodules(submoduleGroupId.value);
});

function deleteSubmodule() {
  submoduleStore.deleteEndSubmodule(submoduleGroupId.value, endSubmodule.value.id).then(() => {
    closeModal();
  });
}
function openModals(modalName, item) {
  activeModal.value = modalName;
  endSubmodule.value = item;
}
</script>

<template>
  <div class="container-fluid admin-view">
    <div class="row">
      <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
        <div class="d-flex align-items-center flex-wrap">
        </div>
        <div @click="openModal('endSubmoduleCreateModal')" class="orange-btn">
          <img src="@/assets/img/plus-ico.svg" alt="" /> Создать субмодуль
        </div>
      </div>
      <div class="table-responsive mt-4">
        <table class="table gray-table">
          <thead>
            <tr>
              <th>Название</th>
              <th>Описание</th>
              <th>Тип</th>
              <th>url</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in endSubmoduleList" :key="item.id">
              <td>
                {{ item.name }}
              </td>
              <td>{{ item.description }}</td>
              <td>{{ endSubmoduleTypeList[item.type] }}</td>
              <td>{{ item.url }}</td>
              <td class="table-buttons">
                <div class="table-button" @click="openModals('endSubmoduleUpdateModal', item)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="openModals('endSubmoduleDeleteModal', item)">
                  <span class="delite-ico" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <DeleteModal v-if="activeModal === 'endSubmoduleDeleteModal'" :itemText="'субмодуль'"
      @confirmDelete="deleteSubmodule()" />
    <EndSubmoduleCreateModal v-if="activeModal === 'endSubmoduleCreateModal'" />
    <EndSubmoduleUpdateModal v-if="activeModal === 'endSubmoduleUpdateModal'" />
  </div>
</template>
