<script setup lang="js">
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import { onMounted } from 'vue';
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import Search from '@/components/Search.vue';
import Sort from '@/components/Sort.vue';
import BusinessProcessCreateModal from '@/components/modals/BusinessProcessCreateModal.vue';
import BusinessProcessUpdateModal from '@/components/modals/BusinessProcessUpdateModal.vue';
import BusinessProcessFixateModal from '@/components/modals/BusinessProcessFixateModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import useModal from '@/stores/modal.store';

const router = useRouter();
const businessProcessStore = useBusinessProcessStore();

const {
  paginatedBPList,
  searchName,
  selectedBPs,
  sortType,
  isSelectAll,
  bp,
  projectId,
  deleteBP
} = storeToRefs(businessProcessStore);
const { activeModal, openModal } = useModal();

function getList() {
  businessProcessStore.getPaginatedBPList();
}

onMounted(() => {
  getList();
});

function selectItem(itemId) {
  if (selectedBPs.value.includes(itemId)) {
    selectedBPs.value = selectedBPs.value.filter((bp) => bp != itemId);
  } else {
    selectedBPs.value.push(itemId);
  }
}
function selectAll() {
  const isActive = isSelectAll.value;
  if (!isActive) {
    const list = paginatedBPList.value.data;
    list.forEach((bp) => {
      if (!selectedBPs.value.includes(bp.id)) {
        selectedBPs.value.push(bp.id);
      }
    });
  } else {
    selectedBPs.value = [];
  }
  isSelectAll.value = !isActive;
}
function changeSortType(newValue) {
  sortType.value = newValue;
  getList();
}
function deleteAll() {
  selectedBPs.forEach((bpId) => {
    deleteBP(bpId);
  });
}
function openUpdateModal(item) {
  bp.value = item;
  openModal('businessProcessUpdateModal');
}
function openFixateModal(item) {
  bp.value = item;
  openModal('businessProcessFixateModal');
}

function handlePageChange(page) {
  businessProcessStore.getPaginatedBPList(page);
};
function redirectTo(projectId, bpId) {
  router.push({
    name: 'bp-id',
    params: {
      projectId,
      bpId
    }
  });
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
        <div class="d-flex align-items-center flex-wrap">
          <Search
            @search="getList()"
            :value="searchName"
            :placeholderText="'Поиск по названию'"
            @input="searchName = $event.target.value"
          />
          <Sort :value="sortType" @applyChange="changeSortType" />
          <!-- <span class="delete-selected-btn" @click="deleteAll()" v-show="selectedBusinesses.length">Удалить выбранные</span> -->
        </div>
        <div class="d-flex justify-content-end flex-wrap align-items-end">
          <Bootstrap5Pagination
            :data="paginatedBPList"
            @pagination-change-page="handlePageChange"
            :limit="2"
          />
          <div @click="openModal('businessProcessCreateModal')" class="orange-btn">
            <img src="@/assets/img/plus-ico.svg" alt="" /> Создать бизнес-процесс
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table gray-table">
          <thead>
            <tr>
              <th class="checkbox-td">
                <div>
                  <span
                    @click="selectAll()"
                    class="table-checkbox"
                    :class="{ active: isSelectAll }"
                  />
                </div>
              </th>
              <th>Бизнес-процесс</th>
              <th>Описание</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="businessProcess in paginatedBPList.data"
              :key="businessProcess.id"
              :class="{ selected: selectedBPs.includes(businessProcess.id) }"
              @click="redirectTo(projectId, businessProcess.id)"
            >
              <td class="checkbox-td">
                <div>
                  <span @click.stop="selectItem(businessProcess.id)" class="table-checkbox" />
                </div>
              </td>
              <td>
                {{ businessProcess.name }}
              </td>
              <td>{{ businessProcess.description }}</td>
              <td>
                <div class="table-buttons">
                  <div class="table-button" @click.stop="openFixateModal(businessProcess)">
                    <span class="fixate-ico" />
                  </div>
                  <div class="table-button" @click.stop="openUpdateModal(businessProcess)">
                    <span class="edit-ico" />
                  </div>
                  <!--
                    <div class="table-button">
                    <span class="delite-ico"/>
                    </div>
                    -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <BusinessProcessUpdateModal v-if="activeModal === 'businessProcessUpdateModal'" />
      <BusinessProcessCreateModal v-if="activeModal === 'businessProcessCreateModal'" />
      <BusinessProcessFixateModal v-if="activeModal === 'businessProcessFixateModal'" />
      <DeleteModal v-if="activeModal === 'deleteModal'" :itemText="'этот БП'" />
    </div>
  </div>
</template>