<script setup lang="js">
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useSubmoduleStore } from '@/stores/submodule.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const submoduleStore = useSubmoduleStore();
const submoduleGroupId = useRouteParams('submoduleGroupId');
const { closeModal } = useModal();

const { updateEndSubmoduleRequest, endSubmoduleTypeList, endSubmodule } = storeToRefs(submoduleStore);

const name = ref(null);
const description = ref(null);
const type = ref(null);
const url = ref(null);

function updateSubmodule() {
  submoduleStore.updateEndSubmodule(submoduleGroupId.value, endSubmodule.value.id, name.value, description.value, type.value, url.value).then(() => {
    closeModal();
  });
}
function isErrorField(fieldName) {
  if (updateEndSubmoduleRequest.value.error?.errors) {
    return updateEndSubmoduleRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!updateEndSubmoduleRequest.value.error?.errors) {
    return updateEndSubmoduleRequest.value.error;
  }
}
onMounted(() => {
  if (endSubmodule.value) {
    name.value = endSubmodule.value.name;
    description.value = endSubmodule.value.description;
    type.value = endSubmodule.value.type;
    url.value = endSubmodule.value.url;
  }
});
</script>

<template>
  <ModalTemplateSide @submit="updateSubmodule">
    <template v-slot:title>Редактировать субмодуль</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Наименование</label>
        <input v-model="name" type="text" class="form-control" :class="{ error: isErrorField('name') }" />
        <p v-if="isErrorField('name')" class="error-mesage">
          <span v-for="error in isErrorField('name')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Описание</label>
        <textarea v-model="description" class="form-control" :class="{ error: isErrorField('description') }"></textarea>
        <p v-if="isErrorField('description')" class="error-mesage">
          <span v-for="error in isErrorField('description')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select v-model="type" class="form-select" :class="{ 'error': isErrorField('type') }">
          <option v-for="typeKey in Object.keys(endSubmoduleTypeList)" :key="typeKey" :value="typeKey">
            {{ endSubmoduleTypeList[typeKey] }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Url</label>
        <input v-model="url" type="text" class="form-control" :class="{ error: isErrorField('url') }" />
        <p v-if="isErrorField('url')" class="error-mesage">
          <span v-for="error in isErrorField('url')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        <span v-for="error in isNotFieldError()">{{ error }}</span>
      </p>
    </template>
    <template v-slot:submit>
      <img v-if="updateEndSubmoduleRequest.isLoading" class="loader" src="@/assets/img/loader.gif" alt="loader.gif" />
      <div v-else>Сохранить</div>
    </template>
  </ModalTemplateSide>
</template>
