<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useViewFieldStore } from '@/stores/viewField.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';

const { closeModal } = useModal();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const { nodeAncestorList } = storeToRefs(inputFieldStore);
const viewFieldStore = useViewFieldStore();
const { viewFieldList, createViewFieldRequest } = storeToRefs(viewFieldStore);

const toggledNodes = ref([]);
const creating = ref({
  field: [],
  catalog: [],
  static: [],
  table: []
})
const removing = ref({
  field: [],
  catalog: [],
  static: [],
  table: []
})
const savedFields = ref({
  field: [],
  catalog: [],
  static: [],
  table: [],
});
const selectAllChecked = ref(false);

function toggleNode(nodeId) {
  if (toggledNodes.value.includes(nodeId)) {
    let nodeIndex = toggledNodes.value.findIndex(value => value === nodeId);
    toggledNodes.value.splice(nodeIndex, 1);
  } else {
    toggledNodes.value.push(nodeId);
  }
}
function isToggledNode(nodeId) {
  if (toggledNodes.value.includes(nodeId)) {
    return true;
  } else {
    return false;
  }
}
function toggleAll() {
}
function selectAll() { }
function fieldName(field) {
  let fieldKeys = Object.keys(field);
  let title = {};
  if (fieldKeys.includes("catalog")) {
    title = field.catalog.title;
  }
  if (fieldKeys.includes("the_table")) {
    title = field.the_table.title;
  }
  if (fieldKeys.includes("static")) {
    title = field.static.title;
  }
  if (fieldKeys.includes("field")) {
    title = field.field.title;
  }
  return title[project.value.default_locale];
}
function fieldType(field) {
  let fieldKeys = Object.keys(field);
  let type = '';
  if (fieldKeys.includes("catalog")) {
    type = "catalog";
  }
  if (fieldKeys.includes("the_table")) {
    type = "table";
  }
  if (fieldKeys.includes("static")) {
    type = "static";
  }
  if (fieldKeys.includes("field")) {
    type = "field";
  }
  return type;
}
function nodeName(node) {
  let title = node.current.node.title;
  return title[project.value.default_locale];
}
function isSavedField(inputField) {
  let inputFieldType = fieldType(inputField);
  for (let viewField of viewFieldList.value) {
    let viewFieldType = savedFieldType(viewField);
    if (inputFieldType === viewFieldType) {
      let viewFieldInputId = viewField[`input_${viewFieldType}_id`];

      if (viewFieldInputId == inputField.id) {
        return viewField.id;
      }
    }
  }

  return false;
}
function isToggledField(field) {
  let inputFieldType = fieldType(field);
  if (isSavedField(field)) {
    if (!removing.value[inputFieldType].includes(isSavedField(field))) {
      return true;
    }
  }
  if (creating.value[inputFieldType].includes(field.id)) {
    return true;
  }
  return false;
}
function toggleField(field) {
  let inputFieldType = fieldType(field);
  if (isToggledField(field)) {
    if (isSavedField(field)) {
      removing.value[inputFieldType].push(isSavedField(field));
    } else {
      creating.value[inputFieldType] = creating.value[inputFieldType].filter((item) => item != field.id);
    }
  } else {
    if (!isSavedField(field)) {
      creating.value[inputFieldType].push(field.id);
      if (removing.value[inputFieldType].includes(field.id)) {
        removing.value[inputFieldType] = removing.value[inputFieldType].filter((item) => item != field.id);
      }
    } else {
      if (removing.value[inputFieldType].includes(field.id)) {
        removing.value[inputFieldType] = removing.value[inputFieldType].filter((item) => item != isSavedField(field));
      }
    }
  }
}
function savedFieldType(field) {
  let fieldKeys = Object.keys(field);
  let type = '';
  if (fieldKeys.includes("input_catalog")) {
    type = "catalog";
  }
  if (fieldKeys.includes("input_table")) {
    type = "table";
  }
  if (fieldKeys.includes("input_static")) {
    type = "static";
  }
  if (fieldKeys.includes("input_field")) {
    type = "field";
  }
  return type;
}
function saveSelected() {
  viewFieldStore.createViewField(
    projectId.value,
    bpId.value,
    nodeId.value,
    creating.value.field,
    creating.value.catalog,
    creating.value.static,
    creating.value.table,
    removing.value.field,
    removing.value.catalog,
    removing.value.static,
    removing.value.table
  ).then(() => {
    closeModal();
  });
}
function isRequestError() {
  return createViewFieldRequest.value.error;
}
onMounted(() => {
  inputFieldStore.clearRequestVariables();
  inputFieldStore.getAncestorList(projectId.value, bpId.value, nodeId.value);
});
</script>

<template>
  <ModalTemplateSide @submit="saveSelected()">
    <template v-slot:title>Поля для просмотра</template>
    <template v-slot:body>
      <p v-if="isRequestError()" class="error-mesage">
        {{ isRequestError() }}
      </p>
      <!-- 
      <div class="d-flex justify-content-between mt-3">
        <div class="form-label d-flex align-items-center">
          <input type="checkbox" class="me-3 modal-checkbox" v-model="selectAllChecked" @change="selectAll" />Выделить
          все
        </div>
        <div class="form-label" @click="toggleAll">
          Свернуть все
          <img src="@/assets/img/minus-ico-with-border.svg" class="ms-3" />
        </div>
      </div> -->
      <div v-for="node in nodeAncestorList" :key="node.id" class="mt-3">
        <div class="modal-nodes d-flex justify-content-between">
          <span>{{ nodeName(node) }}</span>
          <img src="@/assets/img/plus-ico-with-border.svg" v-if="!isToggledNode(node.id)" @click="toggleNode(node.id)"
            class="modal-node_img-default" />
          <img src="@/assets/img/minus-ico-with-border.svg" v-if="isToggledNode(node.id)"
            @click="toggleNode(node.id)" />
        </div>
        <div v-if="isToggledNode(node.id)">
          <div class="d-flex justify-content-between align-items-start mt-3" v-for="input in node.inputs">
            <div class="d-flex align-items-start">
              <div class="checkbox-btn" :class="{ checked: isToggledField(input) }" @click="toggleField(input)" />
              {{ fieldName(input) }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
