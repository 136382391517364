<script setup lang="js">
import { ref, onMounted } from 'vue';
import { useNodeStore } from '@/stores/node.store';
import { useProjectStore } from '@/stores/project.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateCenter from '@/components/modals/ModalTemplateCenter.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const { closeModal } = useModal();
const { deleteNodeRef } = useNodeStore();
const nodeStore = useNodeStore();
const { dataForDeleteNodeRef, deleteNodeRefRequest } = storeToRefs(nodeStore);
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);

const sourceNodeTitle = ref(null);
const targetNodeTitle = ref(null);

function deleteLink() {
  const child = dataForDeleteNodeRef.value.sourceNode.child_nodes.find((child) => child.pivot.next_bp_node_id == dataForDeleteNodeRef.value.targetNode?.current.id)
  const nodeDirectionId = child?.pivot?.id;
  deleteNodeRef(projectId.value, bpId.value, nodeDirectionId).then(() => {
    closeModal();
  });
}
function isHasError() {
  return deleteNodeRefRequest.value.error;
}
onMounted(() => {
  sourceNodeTitle.value = dataForDeleteNodeRef.value.sourceNode?.current?.node?.title[project.value.default_locale];
  targetNodeTitle.value = dataForDeleteNodeRef.value.targetNode?.current?.node?.title[project.value.default_locale];
});
</script>

<template>
  <ModalTemplateCenter @submit="deleteLink">
    <template v-slot:title>Вы уверены, что хотите удалить связь между узлами "{{sourceNodeTitle}}" и "{{targetNodeTitle}}"?</template>
    <template v-slot:body>
      <p v-if="isHasError()" class="error-mesage">
        {{ isHasError() }}
      </p>
    </template>
    <template v-slot:submit>Удалить</template>
  </ModalTemplateCenter>
</template>
