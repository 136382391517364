<script setup lang="js">
import { onMounted, ref, watch } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import CreateTableModal from '@/components/modals/Registry/CreateTableModal.vue';
import DeleteTableModal from '@/components/modals/Registry/DeleteTableModal.vue';
import CreateColumnModal from '@/components/modals/Registry/CreateColumnModal.vue';
import UpdateColumnModal from '@/components/modals/Registry/UpdateColumnModal.vue';
import DeleteColumnModal from '@/components/modals/Registry/DeleteColumnModal.vue';
import CreateRefModal from '@/components/modals/Registry/CreateRefModal.vue';
import DeleteRefModal from '@/components/modals/Registry/DeleteRefModal.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const projectId = useRouteParams('projectId');
const diagramWrapper = ref(null);
const registryStore = useRegistryStore();
const { getRegTableList, initGraph, fixateRegistries } = useRegistryStore();
const { fixateRequest } = storeToRefs(registryStore);
const { activeModal, openModal } = useModal();

function getData() {
  getRegTableList(projectId.value).then(() => {
    initGraph(diagramWrapper.value);
  });
}

function fixate() {
  fixateRegistries(projectId.value);
}

function isHasError() {
  if (!(fixateRequest.value.error?.errors)) {
    return fixateRequest.value.error;
  }
}

onMounted(() => {
  getData();
});
</script>

<template>
  <div class="container-fluid registry-page">
    <p v-if="isHasError()" class="error-mesage">
      {{ isHasError() }}
    </p>
    <div class="registry-buttons d-flex justify-content-end">
      <div @click="fixate()" class="orange-btn">
        Зафиксировать
      </div>
      <div @click="openModal('RegistryCreateTableModal')" class="orange-btn">
        <img src="@/assets/img/plus-ico.svg" alt="" /> Создать таблицу
      </div>
      <div @click="openModal('RegistryDeleteTableModal')" class="orange-btn">
        <img src="@/assets/img/delete-ico.svg" alt="" /> Удалить таблицу
      </div>
      <div @click="openModal('RegistryCreateColumnModal')" class="orange-btn">
        <img src="@/assets/img/plus-ico.svg" alt="" /> Добавить колонку
      </div>
      <!-- 
      <div @click="openModal('RegistryUpdateColumnModal')" class="orange-btn">
        Редактировать колонку
      </div> -->
      <div @click="openModal('RegistryDeleteColumnModal')" class="orange-btn">
        <img src="@/assets/img/delete-ico.svg" alt="" /> Удалить колонку
      </div>
      <div @click="openModal('RegistryCreateRefModal')" class="orange-btn">
        Создать связь
      </div>
      <div @click="openModal('RegistryDeleteRefModal')" class="orange-btn">
        <img src="@/assets/img/delete-ico.svg" alt="" /> Удалить связь
      </div>
    </div>
    <div class="registry-block">
      <div ref="diagramWrapper" class="wrapper"></div>
    </div>
    <CreateTableModal v-if="activeModal === 'RegistryCreateTableModal'" @save="getData()" />
    <DeleteTableModal v-if="activeModal === 'RegistryDeleteTableModal'" @save="getData()" />
    <CreateColumnModal v-if="activeModal === 'RegistryCreateColumnModal'" @save="getData()" />
    <UpdateColumnModal v-if="activeModal === 'RegistryUpdateColumnModal'" @save="getData()" />
    <DeleteColumnModal v-if="activeModal === 'RegistryDeleteColumnModal'" @save="getData()" />
    <CreateRefModal v-if="activeModal === 'RegistryCreateRefModal'" @save="getData()" />
    <DeleteRefModal v-if="activeModal === 'RegistryDeleteRefModal'" @save="getData()" />
  </div>
</template>