<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="js">
import { useAuthStore } from '../stores/auth.store';
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

const authStore = useAuthStore();
const router = useRouter();
const mainMenu = ref(false);
const { authorized } = storeToRefs(authStore);
const route = useRoute();

function logout() {
  closeMenu();
  authStore.logout();
  router.push('/login');
}
onMounted(() => {
  authStore.user();
});
function closeMenu() {
  mainMenu.value = false;
}
function activeRoute(route) {
  return router.currentRoute.value.path.indexOf(route) !== -1;
}
function toggleMenu() {
  mainMenu.value = !mainMenu.value;
}
</script>

<template>
  <div v-if="authorized">
    <div
      v-if="activeRoute('project')"
      class="header"
      @click="toggleMenu()"
      :class="{ 'header-active ': mainMenu }"
    />
    <div
      v-else
      class="header header-admin"
      @click="toggleMenu()"
      :class="{ 'header-active ': mainMenu, 'header-admin-active': mainMenu }"
    />
  </div>
  <div v-if="authorized && mainMenu" class="header-content">
    <div class="header_user-name">
      {{ authStore.userData.name }}
    </div>
    <nav>
      <RouterLink
        class="nav-link pt-3 d-flex"
        :class="{ 'header-menu_active-link': activeRoute('project') }"
        to="/projects"
        @click="closeMenu"
      >
        <img
          src="@/assets/img/projects-ico_gray.svg"
          alt="projects-ico_gray.svg"
          class="pe-1"
          v-if="!activeRoute('project')"
        />
        <img
          src="@/assets/img/projects-ico_white.svg"
          alt="projects-ico_white.svg"
          class="pe-1"
          v-else
        />
        Проекты
      </RouterLink>
      <RouterLink
        class="nav-link pt-3 d-flex"
        :class="{ 'header-menu_active-link': activeRoute('admin') }"
        to="/admin"
        @click="closeMenu"
      >
        <img
          src="@/assets/img/admin-ico_gray.svg"
          alt="admin-ico_gray.svg"
          v-if="!activeRoute('admin')"
          class="pe-1"
        />
        <img src="@/assets/img/admin-ico_white.svg" alt="admin-ico_white.svg" v-else class="pe-1" />
        Администрирование
      </RouterLink>
      <RouterLink
        class="nav-link pt-3 d-flex"
        :class="{ 'header-menu_active-link': activeRoute('submodule-group-list') }"
        to="/submodule-groups"
        @click="closeMenu"
      >
        <img
          src="@/assets/img/admin-ico_gray.svg"
          alt="admin-ico_gray.svg"
          v-if="!activeRoute('submodule-group-list')"
          class="pe-1"
        />
        <img src="@/assets/img/admin-ico_white.svg" alt="admin-ico_white.svg" v-else class="pe-1" />
        Субмодули
      </RouterLink>
    </nav>
    <div class="header_logout d-flex align-items-center pt-3" @click="logout">
      <img src="@/assets/img/logout.svg" alt="logout.svg" class="pe-1" />
      Выйти
    </div>
  </div>
</template>
