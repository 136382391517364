import _ from 'lodash';

import { setAttr } from './utils';


class BaseBox {
  constructor(data, container, options = {}) {
    this._container = container;
    this._data = data;
    this._x = options.x || 0;
    this._y = options.y || 0;
    this._width = options.width || 300;
    this._height = options.height || 0;
    this._fontSize = options.fontSize || 16;

    this._group = null;
  }

  _setProperty = (name) => {
    return (...args) => {
      if (!args.length) return this[`_${name}`];

      const [value, withUpdate=false] = args;
      this[`_${name}`] = value;

      if (withUpdate) this.updateProps();

      return this;
    };
  }

  data = this._setProperty('data');
  x = this._setProperty('x');
  y = this._setProperty('y');
  width = this._setProperty('width');
  height = this._setProperty('height');
  fontSize = this._setProperty('fontSize');

  selection = () => this._group;

  midX = () => this._x + this._width / 2;
  rightX = () => this._x + this._width;
  midY = () => this._y + this._height / 2;
  bottomY = () => this._y + this._height;
}


export class AttributeBox extends BaseBox {
  _class;
  _textEl;
  _delimiter;
  _height = 24;

  constructor(data, container, options) {
    super(data, container, options);

    this._class = options.class;

    this._group = container.append('g');
    this._textEl = this._group.append('text');
    this._delimiter = this._group.append('line');

    this._height = this._fontSize * 1.5;

    this.updateProps();
  }

  updateProps() {
    this._group.attr('transform', `translate(${this._x},${this._y})`);
    const textBBox = setAttr(this._textEl, {
        'font-size': this._fontSize,
        x: this._x + this._fontSize,
        y: this._fontSize,
      })
      .text(`${this._data.name} [${this._data.type}]`)
      .node()
      .getBBox();


    const newWidth = textBBox.width + this._fontSize * 2;

    this._width = _.max([newWidth, this._width]);

    setAttr(this._delimiter, {
      x1: 0 + 3,
      x2: this._width - 3,
      y1: this._height,
      y2: this._height,
    })
  }

  class = this._setProperty('class');
  classname = () => this._class.classname();

  x = (...args) => {
    if (!args.length) return this._class.x() + this._x;

    const [value, withUpdate=false] = args;

    this._x = value;

    if (withUpdate) this.updateProps();

    return this;
  }
  y = (...args) => {
    if (!args.length) return this._class.y() + this._y;

    const [value, withUpdate=false] = args;

    this._y = value;

    if (withUpdate) this.updateProps();

    return this;
  }
  originX = this._setProperty('x');
  originY = this._setProperty('y');

  midX = () => this._class.x() + this._x + this._width / 2;
  rightX = () => this._class.x() + this._x + this._width;
  midY = () => this._class.y() + this._y + this._height / 2;
  bottomY = () => this._class.y() + this._y + this._height;
}

export class ClassBox extends BaseBox {
  _groupRect;
  _toolbarGroup;
  _titleGroup;
  _titleRect;
  _titleText;
  _attributesGroup;
  _attributesRect;
  _attributes;
  _isActive = false;

  constructor(data, container, options) {
    super(data, container, options);

    this._group = container.append('g').classed('class', true);
    this._groupRect = this._group.append('rect');

    this._titleGroup = this._group.append('g')
      .classed('classname', true);
    this._titleRect = this._titleGroup.append(
      () => this._groupRect.clone().node()
    );
    this._titleText = setAttr(this._titleGroup.append('text'), {
      'text-anchor': 'middle',
    });

    this._attributesGroup = this._group.append('g')
      .classed('attributes', true);
    this._attributesRect = this._attributesGroup.append(
      () => this._groupRect.clone().node()
    );

    
    this._attributes = _.chain(this._data.columns)
      .keyBy('id')
      .mapValues(col => {
        return new AttributeBox(col, this._attributesGroup, {
          class: this,
        });
      })
      .value();
    this.updateProps();
  }

  attributes = this._setProperty('attributes');
  active = this._setProperty('isActive');

  classname = () => this._data.id;

  updateProps = () => {
    const rowHeight = this._fontSize * 1.5;

    setAttr(this._group, {
      transform: `translate(${this._x},${this._y})`,
      id: `${this.classname()}Class`,
    });

    const titleWidth = setAttr(this._titleText, {
      'font-size': this._fontSize,
      y: this._fontSize,
      x: this._fontSize * 0.5,
    }).text(this.classname())
      .node()
      .getBBox()
      .width;

    const { width, height } = _.reduce(
      this._attributes,
      (acc, attrBox) => {
        attrBox
          .y(acc.height, true);

        const attrWidth = attrBox.width();

        return {
          width: _.ceil(_.max([acc.width, attrWidth])),
          height: acc.height + attrBox.height(),
        }
      },
      { width: titleWidth, height: rowHeight }
    );

    this._width = width;
    this._height = height;

    setAttr(this._groupRect, { width, height });

    setAttr(this._titleRect, {
      height: rowHeight,
      width,
    });

    this._titleText.attr('x', width/2);

    _.each(this._attributes, (attrBox) => {
      attrBox.width(width, true);
    })

    setAttr(this._attributesRect, {
      y: rowHeight,
      height: height - rowHeight,
      width
    });

    this._group.classed('active', this._isActive);
  }
}