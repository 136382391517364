<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import useModal from '@/stores/modal.store';
import ViewDocCreateModal from '@/components/modals/ViewDocCreateModal.vue';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
import { useProjectStore } from '@/stores/project.store';

const { activeModal, openModal } = useModal();
const inputDocumentsStore = useInputDocumentsStore();
const projectStore = useProjectStore();
const { viewDocumentsList, viewDocumentsChangeOrderRequest } = storeToRefs(inputDocumentsStore);
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const order = ref(false);
const originalDocumentsOrder = ref([]);
const selectedDocument = ref(null);
const { project } = storeToRefs(projectStore);

onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputDocumentsStore.clearRequestVariables();
  inputDocumentsStore.getViewDocumentsList(projectId.value, bpId.value, nodeId.value);
});
function getTitle(title) {
  return [project.value.default_locale];
}
function cancel() {
  viewDocumentsList.value = [...originalDocumentsOrder.value];
  order.value = false;
}
function toggleOrderState() {
  if (!order.value) {
    originalDocumentsOrder.value = [...viewDocumentsList.value];
  }
  order.value = !order.value;
}
function selectForChangeOrder(index) {
  if (order.value) {
    if (selectedDocument.value === null) {
      selectedDocument.value = index;
    } else {
      const selectedItem = viewDocumentsList.value[selectedDocument.value];
      viewDocumentsList.value.splice(selectedDocument.value, 1);
      viewDocumentsList.value.splice(index, 0, selectedItem);
      selectedDocument.value = null;

      viewDocumentsList.value.forEach((doc, idx) => {
        doc.order = idx + 1;
      });
    }
  }
}
function saveOrderState() {
  order.value = false;
  const documentsOrder = viewDocumentsList.value.map((doc, index) => ({
    id: doc.id,
    order: index + 1
  }));

  inputDocumentsStore.viewDocumentsChangeOrder(
    projectId.value,
    bpId.value,
    nodeId.value,
    documentsOrder
  );
}
function isRequestError() {
  if (!(viewDocumentsChangeOrderRequest.value.error?.errors)) {
    return viewDocumentsChangeOrderRequest.value.error;
  }
}
</script>
<template>
  <div class="d-flex justify-content-between align-items-center input-btns">
    <span v-if="order">НАСТРОИТЬ ПОРЯДОК ДОКУМЕНТОВ</span>
    <div v-else class="add-btn" @click="openModal('addDocForViewModal')">
      <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
      Добавить документ
    </div>

    <div class="input-btns_order d-flex">
      <div v-if="order" class="d-flex">
        <div class="gray-btn me-3" @click="cancel()">Отмена</div>
        <div class="orange-btn" @click="saveOrderState">Сохранить изменения</div>
      </div>

      <div v-else class="order-btn" @click="toggleOrderState">Настроить порядок</div>
    </div>
  </div>
  <p v-if="isRequestError()" class="error-mesage">
    {{ isRequestError() }}
  </p>
  <div class="table-responsive">
    <table class="table gray-table">
      <thead>
        <tr>
          <th>Наименование документа</th>
          <th>Генерируемый</th>
          <th>Подпись</th>
        </tr>
      </thead>
      <tbody>
        <tr :class="{ selectedDoc: selectedDocument === index }" v-for="(viewDocument, index) in viewDocumentsList"
          :key="viewDocument.id">
          <td>
            <img v-if="order" src="@/assets/img/order-dots-ico.svg" alt="order-dots-ico.svg"
              @click="selectForChangeOrder(index)" :class="{ hidden: order && selectedDocument === index }" />
            <img v-if="order && selectedDocument === index" src="@/assets/img/order-dots-ico_hover.svg"
              alt="order-dots-ico_hover.svg" />
            {{ getTitle(viewDocument.input_doc.document.title) }}
          </td>
          <td>
            {{ viewDocument.input_doc.templates.length === 0 ? 'Загружаемый' : 'Генерируемый' }}
          </td>
          <td>{{ viewDocument.signable ? 'Да' : 'Нет' }}</td>
        </tr>
      </tbody>
    </table>
    <ViewDocCreateModal v-if="activeModal === 'addDocForViewModal'" />
  </div>
</template>
