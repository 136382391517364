<script setup lang="js">
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAutonodeStore } from '@/stores/autonode.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const autonodeStore = useAutonodeStore();
const { variableTypeList, selectedCustomVariableIndex, custom_variables } = storeToRefs(autonodeStore);
const { closeModal } = useModal();

const name = ref(null);
const title = ref(null);
const type = ref(null);
const default_value = ref(null);

function showDefaultValue() {
  let types = ["double", "boolean", "identificator", "date"];
  if (type.value && types.includes(type.value)) {
    return true;
  }
  return false;
}

function updateVariable() {
  custom_variables.value[selectedCustomVariableIndex.value].name = name.value;
  custom_variables.value[selectedCustomVariableIndex.value].title = title.value;
  custom_variables.value[selectedCustomVariableIndex.value].type = type.value;
  custom_variables.value[selectedCustomVariableIndex.value].default_value = default_value.value;
  closeModal();
}

onMounted(() => {
  let variable = { ...custom_variables.value[selectedCustomVariableIndex.value] };
  name.value = variable.name;
  title.value = variable.title;
  type.value = variable.type;
  default_value.value = variable.default_value;
});
</script>

<template>
  <ModalTemplateSide @submit="updateVariable()">
    <template v-slot:title>Редактировать переменную</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Наименование</label>
        <input type="text" v-model="title" class="form-control" />
      </div>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select v-model="type" class="form-select">
          <option v-for="typeKey in Object.keys(variableTypeList)" :key="typeKey" :value="typeKey">
            {{ variableTypeList[typeKey] }}
          </option>
        </select>
      </div>
      <div class="mt-3" v-if="showDefaultValue()">
        <label class="form-label">Значение по умолчанию</label>
        <input type="date" v-model="default_value" class="form-control" v-if="type == 'date'" />
        <select v-model="default_value" class="form-select" v-else-if="type == 'boolean'">
          <option :value="true">
            Истина
          </option>
          <option :value="false">
            Ложь
          </option>
        </select>
        <input type="number" v-model="default_value" class="form-control" v-else />
      </div>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>