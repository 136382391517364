<script setup lang="js">
import _ from 'lodash'
import { onMounted, ref, watch } from 'vue';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useProjectStore } from '@/stores/project.store';
import useModal from '@/stores/modal.store';
import { useNodeStore } from '@/stores/node.store';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import { useRoute, useRouter } from 'vue-router';
import ContextMenu from '@/components/ContextMenu.vue';
import InputFieldCreateModal from '@/components/modals/InputFieldCreateModal.vue';
import InputCatalogCreateModal from '@/components/modals/InputCatalogCreateModal.vue';
import InputTableCreateModal from '@/components/modals/InputTableCreateModal.vue';
import InputStaticCreateModal from '@/components/modals/InputStaticCreateModal.vue';
import InputFieldUpdateModal from '@/components/modals/InputFieldUpdateModal.vue';
import InputCatalogUpdateModal from '@/components/modals/InputCatalogUpdateModal.vue';
import InputStaticUpdateModal from '@/components/modals/InputStaticUpdateModal.vue';
import InputTableUpdateModal from '@/components/modals/InputTableUpdateModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import FieldTypeContextMenu from '@/components/FieldTypeContextMenu.vue';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const nodeStore = useNodeStore();
const { activeModal } = useModal();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const router = useRouter();
const { project } = storeToRefs(projectStore);
const { inputFieldList, typeList, changeFormInputOrderRequest, deleteFieldRequest, inputField } = storeToRefs(inputFieldStore);
const { bpNode } = storeToRefs(nodeStore);
const showTypeMenu = ref(false);
const isChangeOrderActive = ref(false);
const isChangeOrderMode = ref(false);
const selectedFieldIndex = ref(null);
const route = useRoute();
const originalFieldsOrder = ref([]);

function fieldType(field) {
  let fieldKeys = Object.keys(field);
  if (fieldKeys.includes("catalog")) {
    return "Справочник";
  }
  if (fieldKeys.includes("the_table")) {
    return "Таблица";
  }
  if (fieldKeys.includes("static")) {
    return "Статичное поле";
  }
  if (fieldKeys.includes("field")) {
    return typeList.value[field.field.type]
  }
}

function fieldRequired(field) {
  let fieldKeys = Object.keys(field);
  if (fieldKeys.includes("catalog")) {
    return field.catalog.required;
  }
  if (fieldKeys.includes("the_table")) {
    return field.the_table?.required;
  }
  if (fieldKeys.includes("static")) {
    return "-";
  }
  if (fieldKeys.includes("field")) {
    return field.field.required;
  }
}

function fieldName(field) {
  let fieldKeys = Object.keys(field);
  let title = {};
  if (fieldKeys.includes("catalog")) {
    title = field.catalog.title
  }
  if (fieldKeys.includes("the_table")) {
    title = field.the_table.title
  }
  if (fieldKeys.includes("static")) {
    title = field.static.title
  }
  if (fieldKeys.includes("field")) {
    title = field.field.title
  }

  return title[project.value.default_locale];
}

function openDeleteModal(field) {
  inputField.value = field;
  activeModal.value = 'deleteModal';
}

function openUpdateModal(field) {
  inputField.value = _.cloneDeep(field);
  let fType = fieldType(field);
  if (fType == "Справочник") {
    activeModal.value = 'InputCatalogUpdateModal';
  } else if (fType == "Таблица") {
    activeModal.value = 'InputTableUpdateModal';
  } else if (fType == "Статичное поле") {
    activeModal.value = 'InputStaticUpdateModal';
  } else {
    activeModal.value = 'InputFieldUpdateModal';
  }
}

function openColumnModal(field) {
  inputField.value = field;
  router.push({
    name: 'input-table-id', params: {
      projectId: projectId.value,
      bpId: bpId.value,
      nodeId: nodeId.value,
      inputTableId: field.table_id
    }
  });
}

function selectItem(index) {
  if (!isChangeOrderMode.value) {
    selectedFieldIndex.value = index;
    isChangeOrderMode.value = true;
  } else {
    const selectedItem = inputFieldList.value[selectedFieldIndex.value];
    inputFieldList.value.splice(selectedFieldIndex.value, 1);
    inputFieldList.value.splice(index, 0, selectedItem);
    isChangeOrderMode.value = false;
    selectedFieldIndex.value = null;
  }
}

function saveOrder() {
  inputFieldList.value.forEach((field, index) => {
    field.order = index
  });
  let fields = [];
  let tables = [];
  let catalogs = [];
  let statics = [];
  const originalFields = inputFieldList.value.filter((inputField) => "field" in inputField);
  const originalTables = inputFieldList.value.filter((inputField) => "the_table" in inputField);
  const originalCatalogs = inputFieldList.value.filter((inputField) => "catalog" in inputField);
  const originalStatics = inputFieldList.value.filter((inputField) => "static" in inputField);
  originalFields.forEach((item) => {
    fields.push({
      id: item.id,
      order: item.order
    })
  })
  originalTables.forEach((item) => {
    tables.push({
      id: item.id,
      order: item.order
    })
  })
  originalCatalogs.forEach((item) => {
    catalogs.push({
      id: item.id,
      order: item.order
    })
  })
  originalStatics.forEach((item) => {
    statics.push({
      id: item.id,
      order: item.order
    })
  })
  isChangeOrderMode.value = false;
  selectedFieldIndex.value = null;
  isChangeOrderActive.value = false;
  inputFieldStore.changeFormInputOrder(projectId.value, bpId.value, nodeId.value, fields, tables, catalogs, statics);
}

function doDeleteField() {
  activeModal.value = null;
  inputFieldStore.deleteField(projectId.value, bpId.value, nodeId.value, inputField.value);
}

function isChildSettingsLinkShow() {
  if (bpNode?.current?.node?.direction_type == 'one') {
    return true
  } else {
    return false
  }
}

function activeOrderState() {
  if (!isChangeOrderActive.value) {
    originalFieldsOrder.value = [...inputFieldList.value];
  }
  isChangeOrderActive.value = !isChangeOrderActive.value;
}

function cancelOrdering() {
  inputFieldList.value = [...originalFieldsOrder.value];
  isChangeOrderActive.value = false;
}
function isChangeOrderError() {
  return changeFormInputOrderRequest.value.error;
}
function isDeleteFieldError() {
  return deleteFieldRequest.value.error;
}

onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputFieldStore.getInputFieldList(projectId.value, bpId.value, nodeId.value);
  if (Object.keys(bpNode.value).length == 0) {
    nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
  }
});
watch(() => nodeId.value, () => {
  inputFieldStore.getInputFieldList(projectId.value, bpId.value, nodeId.value);
  nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
});
</script>
<template>
  <div v-if="route.name == 'input-fields'">
    <div class="d-flex align-items-center justify-content-between input-fields__btns">
      <div class="input-fields__add-field-btn">
        <button class="simple-btn mt-3 mb-3 ml-3" @click="showTypeMenu = !showTypeMenu">
          <span class="orange-plus" />
          Добавить поле
        </button>
        <FieldTypeContextMenu v-show="showTypeMenu" @closeMenu="showTypeMenu = false" />
      </div>
      <div class="d-flex align-items-center justify-content-end flex-wrap mr-3">
        <template v-if="isChangeOrderActive == false">
          <RouterLink :to="{
            name: 'childs',
            params: {
              projectId: projectId,
              bpId: bpId,
              nodeId: nodeId
            }
          }" class="simple-underline-btn" v-if="isChildSettingsLinkShow">
            Дочерние узлы
          </RouterLink>
          <div class="simple-underline-btn" @click="activeOrderState()">Настроить порядок</div>
        </template>
        <template v-else>
          <div class="gray-btn" @click="cancelOrdering()" :disabled="changeFormInputOrderRequest.isLoading">
            Отмена
          </div>
          <div class="orange-btn" @click="saveOrder()" :disabled="changeFormInputOrderRequest.isLoading">
            Сохранить изменения
          </div>
        </template>
      </div>
    </div>
    <p v-if="isChangeOrderError()" class="error-mesage">
      {{ isChangeOrderError() }}
    </p>
    <p v-if="isDeleteFieldError()" class="error-mesage">
      {{ isDeleteFieldError() }}
    </p>

    <div class="table-responsive pb-5">
      <table class="table gray-table">
        <thead>
          <tr>
            <th>Наименование поля</th>
            <th>Тип</th>
            <th>Обязательность</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(inputField, rowIndex) in inputFieldList" :key="rowIndex"
            :class="{ 'order-selected': selectedFieldIndex == rowIndex }">
            <td>
              <div class="d-flex align-items-center">
                <span class="columns-settings_drag-btn mt-0" @click="selectItem(rowIndex)"
                  v-show="isChangeOrderActive == true" />
                <span class="name">{{ fieldName(inputField) }}</span>
              </div>
            </td>
            <td>{{ fieldType(inputField) }}</td>
            <td>
              <template v-if="fieldRequired(inputField)">
                Да
              </template>
              <template v-else>
                Нет
              </template>
            </td>
            <td>
              <ContextMenu :menuItems="['columns', 'update', 'delete']" @delete="openDeleteModal(inputField)"
                @update="openUpdateModal(inputField)" @columns="openColumnModal(inputField)"
                v-if="fieldType(inputField) == 'Таблица'" />
              <ContextMenu :menuItems="['update', 'delete']" @delete="openDeleteModal(inputField)"
                @update="openUpdateModal(inputField)" v-else />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <router-view />
  <InputFieldCreateModal v-if="activeModal === 'InputFieldCreateModal'" />
  <InputCatalogCreateModal v-if="activeModal === 'InputCatalogCreateModal'" />
  <InputTableCreateModal v-if="activeModal === 'InputTableCreateModal'" />
  <InputStaticCreateModal v-if="activeModal === 'InputStaticCreateModal'" />
  <DeleteModal v-if="activeModal === 'deleteModal'" :itemText="'это поле'" @confirmDelete="doDeleteField()" />
  <InputFieldUpdateModal v-if="activeModal === 'InputFieldUpdateModal'" />
  <InputCatalogUpdateModal v-if="activeModal === 'InputCatalogUpdateModal'" />
  <InputStaticUpdateModal v-if="activeModal === 'InputStaticUpdateModal'" />
  <InputTableUpdateModal v-if="activeModal === 'InputTableUpdateModal'" />
</template>