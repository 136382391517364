<script setup lang="js">
import useModal from '@/stores/modal.store';
const { closeModal } = useModal();
const props = defineProps({
  hideButtons: {
    type: Boolean,
    default: false
  }
});
function close() {
  closeModal();
}
</script>

<template>
  <div class="transparent-bg d-flex justify-content-start">
    <div class="row half-page justify-content-between">
      <div class="modal-top">
        <h1 class="modal-title"><slot name="title"></slot></h1>
        <img
          src="@/assets/img/close-img.svg"
          alt="close-img.svg"
          @click="close"
          class="modal-close-ico"
        />
        <slot name="body"></slot>
      </div>
      <div class="d-flex justify-content-between align-items-end pt-5" v-if="!hideButtons">
        <button class="btn-default btn-cancel" @click="close()">Отмена</button>
        <button class="btn-default btn-primary" @click="$emit('submit')">
          <slot name="submit"></slot>
        </button>
      </div>
    </div>
  </div>
</template>
