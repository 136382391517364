<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectTeamStore } from '@/stores/projectTeam.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import Search from '@/components/Search.vue';
import useModal from '@/stores/modal.store';
import ProjectTeamUserModal from '@/components/modals/ProjectTeamUserModal.vue';

const projectTeamStore = useProjectTeamStore();
const projectId = useRouteParams('projectId');
const { teamUserList, searchName, setUserPermissionRequest, sortType } = storeToRefs(projectTeamStore);
const { getTeamUserList, setUserTeamPermission } = useProjectTeamStore();
const { activeModal, openModal } = useModal();

onMounted(() => {
  getTeamUserList();
});

function changeSortType(newValue) {
  sortType.value = newValue;
  isSortListOpen.value = !isSortListOpen.value;
  getTeamUserList();
}

const sortTypes = ref({
  1: 'по алфавиту А-Я',
  2: 'дате добавления ↑',
  3: 'дате добавления ↓'
});
let isSortListOpen = ref(false);

function setPermission(user_id, permission, permission_state) {
  setUserTeamPermission(projectId.value, user_id, permission, permission_state);
}
function isHasError() {
  return setUserPermissionRequest.value.error;
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
        <div class="d-flex align-items-center flex-wrap">
          <Search @search="getTeamUserList()" :value="searchName" @input="searchName = $event.target.value"
            :placeholderText="'Поиск пользователя'" />
          <!-- 
          <div class="sort-block d-flex">
            <div>
              Сортировка:
              <span>
                {{ sortTypes[sortType] }}
                <img src="@/assets/img/sort-list-ico.svg" alt="" @click="isSortListOpen = !isSortListOpen"
                  :class="{ isSortListOpen }" />
              </span>
            </div>
            <div v-show="isSortListOpen" class="sort-type-list">
              <div v-for="typeKey in Object.keys(sortTypes)" v-show="sortType != typeKey" @click="changeSortType(typeKey)">
                {{ sortTypes[typeKey] }}
              </div>
            </div>
          </div> -->
        </div>
        <div class="d-flex justify-content-end flex-wrap align-items-end">
          <div @click="openModal('ProjectTeamUserModal')" class="orange-btn">
            Доступные пользователи
          </div>
        </div>
      </div>
      <p v-if="isHasError()" class="error-mesage">
        {{ isHasError() }}
      </p>
      <div class="table-responsive">
        <table class="table gray-table">
          <thead>
            <tr>
              <th>Пользователь</th>
              <th>Редактирование</th>
              <th>Фиксация</th>
              <th>Тестовая заливка</th>
              <th>Боевая заливка</th>
              <th>Управление командой</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in teamUserList">
              <td>
                {{ user.name }}
              </td>
              <td>
                <img v-if="setUserPermissionRequest.isLoading &&
                  setUserPermissionRequest.userId === user.id &&
                  setUserPermissionRequest.permissionName === 'project_update'" class="loader"
                  src="@/assets/img/loader.gif" alt="loader.gif" />
                <span v-else
                  @click="setPermission(user.id, 'project_update', !user.permissions.includes('project_update'))"
                  class="table-checkbox" :class="{ active: user.permissions.includes('project_update') }" />
              </td>
              <td>
                <img v-if="setUserPermissionRequest.isLoading &&
                  setUserPermissionRequest.userId === user.id &&
                  setUserPermissionRequest.permissionName === 'project_fixation'" class="loader"
                  src="@/assets/img/loader.gif" alt="loader.gif" />
                <span v-else
                  @click="setPermission(user.id, 'project_fixation', !user.permissions.includes('project_fixation'))"
                  class="table-checkbox" :class="{ active: user.permissions.includes('project_fixation') }" />
              </td>
              <td>
                <img v-if="setUserPermissionRequest.isLoading &&
                  setUserPermissionRequest.userId === user.id &&
                  setUserPermissionRequest.permissionName === 'project_deploy_test'" class="loader"
                  src="@/assets/img/loader.gif" alt="loader.gif" />
                <span v-else
                  @click="setPermission(user.id, 'project_deploy_test', !user.permissions.includes('project_deploy_test'))"
                  class="table-checkbox" :class="{ active: user.permissions.includes('project_deploy_test') }" />
              </td>
              <td>
                <img v-if="setUserPermissionRequest.isLoading &&
                  setUserPermissionRequest.userId === user.id &&
                  setUserPermissionRequest.permissionName === 'project_deploy_prod'" class="loader"
                  src="@/assets/img/loader.gif" alt="loader.gif" />
                <span v-else
                  @click="setPermission(user.id, 'project_deploy_prod', !user.permissions.includes('project_deploy_prod'))"
                  class="table-checkbox" :class="{ active: user.permissions.includes('project_deploy_prod') }" />
              </td>
              <td>
                <img v-if="setUserPermissionRequest.isLoading &&
                  setUserPermissionRequest.userId === user.id &&
                  setUserPermissionRequest.permissionName === 'project_team_management'" class="loader"
                  src="@/assets/img/loader.gif" alt="loader.gif" />
                <span v-else
                  @click="setPermission(user.id, 'project_team_management', !user.permissions.includes('project_team_management'))"
                  class="table-checkbox" :class="{ active: user.permissions.includes('project_team_management') }" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ProjectTeamUserModal v-if="activeModal === 'ProjectTeamUserModal'" />
    </div>
  </div>
</template>
