<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useCriterionStore } from '@/stores/criterion.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const projectStore = useProjectStore();
const criterionStore = useCriterionStore();
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const title = ref({});
const { project } = storeToRefs(projectStore);
const { createCriterionRequest } = storeToRefs(criterionStore);

onMounted(() => {
  projectStore.getProject(projectId.value);
});

function createCriterion() {
  criterionStore.createCriterion(projectId.value, title.value).then(() => {
    closeModal();
  });
}
function isErrorField(fieldName) {
  if (createCriterionRequest.value.error?.errors) {
    return createCriterionRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createCriterionRequest.value.error?.errors)) {
    return createCriterionRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createCriterion">
    <template v-slot:title>Создать критерий</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование критерия на {{ locale.name }}</label>
        <input type="text" v-model="title[locale.id]" class="form-control" :class="{'error': isErrorField('title')}" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
